import React, { useEffect, useState } from "react";
import { useParams, useLocation, Link } from "react-router-dom";
import axios from 'axios';

import DashboardHeader from "../components/Dashboard-Header";
import ServerOptions from "../components/Server-Options";
import Copyright from "../components/Copyright";
import ComponentHeader from "./components/ServerHeader";

import { serverSizes } from '../../open-routes/components/ServerSizes';

import ServerDetails from "./components/ServerDetails";
import CreateApp from "./components/CreateApp";
import BackupManager from "./components/ServerBackup";
import CronJobs from "./components/CronJobs";
// import DBManager from "./components/DBManager";
import MonitorLogs from "./components/MonitoringAndLogs";
// import PHPManager from "./components/PHPManager";
import ServiceManager from "./components/ServicesManager";
import SSLCerts from "./components/SSLCerts";
import UpgradePlan from "./components/UpgradePlan"

import PageNotFound from '../../../images/404.png';

function ManageServer(userData) {
    const user = userData.user;
    const compartment = "server"

    // const navigate = useNavigate();

    // useEffect(() => {
	// 	const intervalId = setInterval(() => {
    //         if (!user) {
    //             navigate("/signin");
    //         }
	// 	}, 5000);
	
	// 	return () => clearInterval(intervalId);
	// }, []);
    
    // const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const { collectionName } = useParams();
    // const navigate = useNavigate();
    const location = useLocation();

    const [server, setServer] = useState({});
    const [statusCheck, setStatusCheck] = useState("");
    const [healthCheck, setHealthCheck] = useState("");
    const [serverStatus, setServerStatus] = useState("");

    const [loading, setLoading] = useState(true);

    // const toggleDropdown = () => {
    //     setIsDropdownOpen(prevState => !prevState);
    // }

    const [activeTab, setActiveTab] = useState(() => {
        const query = new URLSearchParams(location.search);
        return query.keys().next().value || "serverDetails";
    });

    // Update the tab based on URL query parameter
    useEffect(() => {
        const getActiveTabFromUrl = () => {
            const query = new URLSearchParams(location.search);
            return query.keys().next().value || "serverDetails";
        };

        const tab = getActiveTabFromUrl();
        setActiveTab(tab);
    }, [location.search]);

    // const handleSelectedOption = (optionValue) => {
    //     navigate(`/dashboard/manageserver/${collectionName}?${optionValue}`);
    // };

    useEffect(() => {
        const fetchServers = async () => {
            try {
                const response = await axios.post(`${process.env.REACT_APP_PAYMENT_AUTHENTICATION_MAIL_API}/auth/getServerList`,
                    { poorvaID: user.poorvaID },
                    { withCredentials: true }
                );
                setServer(response.data.collectionsWithInstanceID[collectionName][0]);
            } catch (error) {
                console.error("Error fetching servers:", error);
            }
        };

        fetchServers();

        const intervalId = setInterval(fetchServers, 10000);

        return () => clearInterval(intervalId);

    }, [user.poorvaID, collectionName]);

    console.log(server);

    const serverInstance = server.instanceType;
    const serverDetails = serverSizes.find(server => server.value === serverInstance);

    useEffect(() => {
        const getServerCheck = async () => {
            try {
                const response = await axios.post(`${process.env.REACT_APP_SERVER_AND_APP_MANAGEMENT_API}/api/describeInstanceStatus`,
                    {
                        serverName: server.serverName,
                        poorvaID: user.poorvaID
                    },
                    { withCredentials: true }
                );
                setHealthCheck(response.data?.data?.instanceStatus?.status);
            } catch (err) {
                setHealthCheck("error");
            }
        };

        if (server.serverName) {
            getServerCheck();
        }
        

        const intervalId = setInterval(getServerCheck, 10000);

        return () => clearInterval(intervalId);

    }, [server.serverName, user.poorvaID]);


    useEffect(() => {
        const getServerStatus = async () => {
            try {
                const response = await axios.post(`${process.env.REACT_APP_SERVER_AND_APP_MANAGEMENT_API}/api/getEC2InstanceDetails`,
                    {
                        serverName: server.serverName,
                        poorvaID: user.poorvaID
                    },
                    { withCredentials: true }
                );
                setStatusCheck(response.data?.data?.status);
                // console.log("MANAGE: ", response.data.data)
            } catch (err) {
                setStatusCheck("error");
            }
        };

        if (server.serverName) {
            getServerStatus();
        }

        const intervalId = setInterval(getServerStatus, 10000);

        return () => clearInterval(intervalId);

    }, [server.serverName, user.poorvaID]);


    useEffect(() => {
        if (statusCheck === "running") {
            setServerStatus(healthCheck === "ok" ? "running" : healthCheck);
        } else if (statusCheck === "error") {
            setServerStatus("");
        } else {
            setServerStatus(statusCheck);
        }
    }, [healthCheck, statusCheck]);

    useEffect(() => {
        // // Set a timer to simulate loading for 2 seconds
        // const timer = setTimeout(() => {
        //     setLoading(false);
        // }, 3000);

        // // Clear the timer if the component unmounts before the timer completes
        // return () => clearTimeout(timer);
        if (serverStatus) {
            setLoading(false);
        }
    }, [serverStatus]);
    
    
    /////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////
    
    const renderContent = () => {
        if (loading) {
            return (
                <div className='h-screen md:h-[70vh] flex flex-col justify-center items-center'>
                    <div className="flex justify-center items-center gap-2">
                        <div className="spinner"></div>
                        <p className='font-bold'>Loading...</p>
                    </div>
                </div>
            );
        };

        if (serverStatus === "") {
            return (
                <div className="p-5 flex lg:flex-row-reverse flex-col justify-center items-center gap-16 lg:gap-8 text-black">

                    <div className="w-full lg:w-[60%] flex justify-center items-center"><img className="w-full md:w-[80%] lg:w-full" src={PageNotFound} alt="" /></div>

                    <div className="w-full lg:w-[40%]">
                        <h1 className="text-[90px] font-bold text-center leading-14 text-custom-green">404<br/>error</h1>
                        <p className="text-sm text-center mt-10">It seems the page you were looking for has taken a detour or is on vacation. Don't worry, though – even the best explorers find themselves off the beaten path from time to time.</p>
                        <Link className="mt-4 elastic-button1 px-6 py-1 cursor-pointer rounded" to="/dashboard/products"><i className="fa-solid fa-server"></i><span className="pl-4">Products</span></Link>
                    </div>

                </div>
            );
        };

        switch (activeTab) {
            case "serverDetails":
                return (
                    <ServerDetails
                        userData={user}
                        server={server}
                        serverDetails={serverDetails}
                        collection={collectionName}
                        serverStatus={serverStatus}
                    />
                );
            case "createApp":
                return serverStatus === "running" ? (
                    <CreateApp userData={user} collection={collectionName} server={server} />
                ) : (
                    <div className="flex justify-center items-center w-full text-red-700 text-lg font-bold">
                        <p>Server is not running.</p>
                    </div>
                );
            case "backupRestore":
                return serverStatus === "running" ? (
                    <BackupManager userData={user} collection={collectionName} />
                ) : (
                    <div className="flex justify-center items-center w-full text-red-700 text-lg font-bold">
                        <p>Server is not running.</p>
                    </div>
                );
            case "cronJobs":
                return serverStatus === "running" ? (
                    <CronJobs userData={user} collection={collectionName} />
                ) : (
                    <div className="flex justify-center items-center w-full text-red-700 text-lg font-bold">
                        <p>Server is not running.</p>
                    </div>
                );
            // case "dbManager":
            //     return serverStatus === "running" ? (
            //         <DBManager userData={user} collection={collectionName} />
            //     ) : (
            //         <div className="flex justify-center items-center w-full text-red-700 text-lg font-bold">
            //             <p>Server is not running.</p>
            //         </div>
            //     );
            case "monitoring":
                return serverStatus === "running" ? (
                    <MonitorLogs userData={user} collection={collectionName} />
                ) : (
                    <div className="flex justify-center items-center w-full text-red-700 text-lg font-bold">
                        <p>Server is not running.</p>
                    </div>
                );
            // case "phpManager":
            //     return serverStatus === "running" ? (
            //         <PHPManager userData={user} collection={collectionName} />
            //     ) : (
            //         <div className="flex justify-center items-center w-full text-red-700 text-lg font-bold">
            //             <p>Server is not running.</p>
            //         </div>
            //     );
            case "servicesManager":
                return serverStatus === "running" ? (
                    <ServiceManager userData={user} collection={collectionName} />
                ) : (
                    <div className="flex justify-center items-center w-full text-red-700 text-lg font-bold">
                        <p>Server is not running.</p>
                    </div>
                );
            case "sslCertificates":
                return serverStatus === "running" ? (
                    <SSLCerts userData={user} collection={collectionName} />
                ) : (
                    <div className="flex justify-center items-center w-full text-red-700 text-lg font-bold">
                        <p>Server is not running.</p>
                    </div>
                );
            case "upgradePlan":
                return serverStatus === "running" ? (
                    <UpgradePlan
                        server={server}
                        serverDetails={serverDetails}
                        serverSizes={serverSizes}
                    />
                ) : (
                    <div className="flex justify-center items-center w-full text-red-700 text-lg font-bold">
                        <p>Server is not running.</p>
                    </div>
                );
            default:
                return (
                    <div className="p-5 flex lg:flex-row-reverse flex-col justify-center items-center gap-16 lg:gap-8 text-black">

                        <div className="w-full lg:w-[60%] flex justify-center items-center"><img className="w-full md:w-[80%] lg:w-full" src={PageNotFound} alt="" /></div>

                        <div className="w-full lg:w-[40%]">
                            <h1 className="text-[90px] font-bold text-center leading-14 text-custom-green">404<br/>error</h1>
                            <p className="text-sm text-center mt-10">It seems the page you were looking for has taken a detour or is on vacation. Don't worry, though – even the best explorers find themselves off the beaten path from time to time.</p>
                            <Link className="mt-4 elastic-button1 px-6 py-1 cursor-pointer rounded" to="/dashboard/products"><i className="fa-solid fa-server"></i><span className="pl-4">Products</span></Link>
                        </div>

                    </div>
                );
        }
    };

    /////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////

    return (
        <>
            <DashboardHeader userData={user} compartment={compartment} activeServerTab={activeTab}/>
            
            <div className="flex lg:flex-row flex-col">
                <div className="hidden md:block lg:w-[27%] items-center">
                    <ServerOptions activeServerTab={activeTab}/>
                </div>

                <div className="h-screen overflow-y-auto lg:w-[73%]">
                    <div className="flex flex-col justify-center text-black">
                        <ComponentHeader
                            plan={serverDetails?.name}
                            serverName={server.serverName}
                            serverStatus={serverStatus}
                        />
                        <div className="lg:pr-20 lg:pl-5 lg:py-5 p-5 mx-5 mb-5 text-left bg-white rounded-b rounded-t-none shadow-custom3">
                            {renderContent()}
                        </div>
                    </div>
                </div>
            </div>

            <Copyright />
        </>
    )

}

export default ManageServer;