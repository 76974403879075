import React, {useEffect} from "react";
// import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';

function CodeEditor(userData) {
    // const user = userData.userData;
    const collectionName = userData.collection;

    useEffect(() => {
        document.title = "Code Editor - Manage & Edit your files";
    }, []);
    
    return (
        <>
            <div className="flex mb-10 gap-2 items-center text-xs font-bold flex-wrap">
                <Link to="/dashboard" className="cursor-pointer hover:text-custom-green">
                    Dashboard
                </Link>
                <i className="fa-solid fa-chevron-right"></i>
                <Link to="/dashboard/products" className="cursor-pointer hover:text-custom-green">
                    Products
                </Link>
                <i className=" fa-solid fa-chevron-right"></i>
                <Link to={`/dashboard/manageserver/${collectionName}?createApp`} className="cursor-pointer hover:text-custom-green">
                    Application Manager
                </Link>
                <i className="fa-solid fa-chevron-right"></i>
                <p className="text-custom-green">
                    Code Editor
                </p>
            </div>
            
            {/* The content goes here */}
            <div>
                <h1 className="text-xl uppercase font-bold">Code Editor</h1>
                <p className="font-light">Edit and refine your code in real-time with our intuitive Code Editor.</p>
            </div>

            <div className="flex flex-col mt-10">
                
            </div>
        </>
    )
}

export default CodeEditor;
