import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import successImage from "../../../images/SuccessfulPayment.gif"

const PaymentSuccess = () => {
    const navigate = useNavigate();
    useEffect(() => {
        document.title = "Success - The payment has been paid successfully";
    }, []);

    setTimeout(() => {
        navigate('/dashboard/payment');
    }, 3000);

    return (
        <>
            <div className="h-screen w-full flex justify-center items-center">
                <div className="w-[250px] h-[250px] flex flex-col justify-center items-center gap-2">
                    <h3 className="text-[20px] font-bold text-green-700">Card added!</h3>
                    <img alt="" src={successImage} className="rounded-xl shadow-custom3"/>
                </div>
            </div>
        </>
    );
};

export default PaymentSuccess;
