import React, { useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import axios from "axios";

// import Loading from "../../../components/Loading";
import DashboardHeader from "../../components/Dashboard-Header";
import DashboardOptions0 from "../../components/Dashboard-Options-0"
import SetupForm from './SetupForm';
import Copyright from '../../components/Copyright';

import Visa from '../../../../images/visa-card.png'
import Master from '../../../../images/master-card.png'
import Amex from '../../../../images/amex.png'
import Discover from '../../../../images/Discover-pay.png'
import Diners from '../../../../images/diners-club.png'
import Jcb from '../../../../images/JCB-payment.png'
import UPChina from '../../../../images/UnionPay_China.png'
import Eftpos from '../../../../images/eftpos_Au.png'

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe('pk_test_51OA77FSCHO986P588TW2ihcwKNI5P0aZD5mmVcJkQu6Q2ilgqiY1sZseJ30J1EZ4coNzjxxzW3cqKklVllvr5Ujc00fkQBU0Vx');

function Payment(userData) {
    const user = userData.user;
    const compartment = "dashboard";

    useEffect(() => {
        document.title = "Manage your payment methods";
    }, []);

    const selectedItem0 = "payment";
    const [message, setMessage] = useState('');
    const [customer, setCustomer] = useState('');
    const [defaultPaymentId, setDefaultPaymentId] = useState('');
    const [confirmStates, setConfirmStates] = useState({});
    const [options, setOptions] = useState({
        appearance: {
            theme: 'stripe',
        },
    });

    const [cards, setCards] = useState([]);
    const [loadingStates, setLoadingStates] = useState({});
    const [deleteLoadingStates, setDeleteLoadingStates] = useState({});

    useEffect(() => {
        const getSecretAndOptions = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_PAYMENT_AUTHENTICATION_MAIL_API}/auth/checkout`, { withCredentials: true });
                const clientSecret = response.data.client_secret;
                const customerID = response.data.customer;
                setCustomer(customerID);
                const res = await axios.post(`${process.env.REACT_APP_PAYMENT_AUTHENTICATION_MAIL_API}/auth/secret`, { customer: customerID }, { withCredentials: true });

                setCards(res.data.paymentMethods);
                setDefaultPaymentId(res.data.defaultPaymentMethodId);

                setOptions((prevOptions) => ({
                    ...prevOptions,
                    clientSecret: clientSecret,
                }));

                const initialConfirmStates = res.data.paymentMethods.reduce((acc, card) => {
                    acc[card.id] = false;
                    return acc;
                }, {});
                setConfirmStates(initialConfirmStates);

                const initialLoadingStates = res.data.paymentMethods.reduce((acc, card) => {
                    acc[card.id] = false;
                    return acc;
                }, {});
                setLoadingStates(initialLoadingStates);
                setDeleteLoadingStates(initialLoadingStates);

            } catch (error) {
                console.log(error);
            }
        };

        getSecretAndOptions();
    }, []);

    const deleteCard = async (cardID) => {
        setMessage('');

        try {
            setDeleteLoadingStates((prevLoadingStates) => ({
                ...prevLoadingStates,
                [cardID]: true,
            }));

            const response = await axios.post(`${process.env.REACT_APP_PAYMENT_AUTHENTICATION_MAIL_API}/auth/remove-payment-method`, { cardID }, { withCredentials: true });
            setMessage(response.data.message);
            setTimeout(() => {
                window.location.reload();
            }, 3000);
        } catch (error) {
            console.log(error);
        }
    };

    const setDefaultPaymentMethod = async (cardID) => {
        setMessage('');

        try {
            setLoadingStates((prevLoadingStates) => ({
                ...prevLoadingStates,
                [cardID]: true,
            }));

            const response = await axios.post(`${process.env.REACT_APP_PAYMENT_AUTHENTICATION_MAIL_API}/auth/set-default-payment-method`, {
                customer: customer,
                paymentMethodId: cardID,
            }, { withCredentials: true });

            setMessage(response.data.message);
            setTimeout(() => {
                window.location.reload();
            }, 3000);
        } catch (error) {
            console.log(error);
        }
    };

    // if (!options.clientSecret) {
    //     return <Loading />;
    // };

    return (
        <>
            <DashboardHeader userData={user} selectedItem0={selectedItem0} compartment={compartment}/>
            <div className="flex lg:flex-row flex-col">
                
                <div className="hidden lg:block lg:w-[27%]">
                    <DashboardOptions0 selectedItem0={selectedItem0} />
                </div>

                {!options.clientSecret ? (
                    <div className='h-screen overflow-y-auto lg:w-[73%] flex justify-center items-center gap-1 font-bold'>
                        {/* <Loading /> */}
                        <div className='spinner'></div>
                        <p>Loading...</p>
                    </div>
                ) : (
                    <div className="h-screen overflow-y-auto mt-3 lg:w-[73%]">
                        <div className='bg-white flex flex-col gap-2 m-5 md:shadow-custom3 rounded py-10 sm:pl-10 sm:pr-10 lg:pl-5 lg:pr-20 items-start'>
                            <div className="w-full text-[14.5px]">
                                {cards.length > 0 && (
                                    <div className='mt-2 mb-10'>
                                        <p className='font-bold text-lg uppercase'>Card List:</p>
                                        {cards.map((card, index) => (
                                            <div key={index} className='mt-2 w-full bg-white rounded shadow px-4 py-3'>
                                                <div className='flex justify-between items-center font-bold'>
                                                    <div className='flex justify-start items-center gap-4'>
                                                        {(card.brand === 'visa' || card.brand === 'visa_debit') && (
                                                            <img alt="" className='drop-shadow w-10 h-6 rounded' src={Visa} />
                                                        )}
                                                        {(card.brand === 'mastercard' || card.brand === 'mastercard_debit' || card.brand === 'mastercard_prepaid') && (
                                                            <img alt="" className='drop-shadow w-10 h-6 rounded' src={Master} />
                                                        )}
                                                        {(card.brand === 'amex' || card.brand === 'amex2') && (
                                                            <img alt="" className='drop-shadow w-10 h-6 rounded' src={Amex} />
                                                        )}
                                                        {(card.brand === 'discover' || card.brand === 'discover2') && (
                                                            <img alt="" className='drop-shadow w-10 h-6 rounded' src={Discover} />
                                                        )}
                                                        {(card.brand === 'diners' || card.brand === 'diners_14digits') && (
                                                            <img alt="" className='drop-shadow w-10 h-6 rounded' src={Diners} />
                                                        )}
                                                        {card.brand === 'jcb' && (
                                                            <img alt="" className='drop-shadow w-10 h-6 rounded' src={Jcb} />
                                                        )}
                                                        {card.brand === 'unionpay' && (
                                                            <img alt="" className='drop-shadow w-10 h-6 rounded' src={UPChina} />
                                                        )}
                                                        {card.brand === 'interac' && (
                                                            <img alt="" className='drop-shadow w-10 h-6 rounded' src={Visa} />
                                                        )}
                                                        {card.brand === 'eftpos_au_debit' && (
                                                            <img alt="" className='drop-shadow w-10 h-6 rounded' src={Eftpos} />
                                                        )}
                                                        {card.brand === 'eftpos_au_visa_debit' && (
                                                            <img alt="" className='drop-shadow w-10 h-6 rounded' src={Visa} />
                                                        )}
                                                        {card.brand === 'eftpos_au_mastercard_debit' && (
                                                            <img alt="" className='drop-shadow w-10 h-6 rounded' src={Master} />
                                                        )}
                                                        <p>•••• {card.last4}</p>
                                                    </div>
                                                    {card.id !== defaultPaymentId &&
                                                        <div className='flex flex-col sm:flex-row justify-between items-center gap-2'>
                                                            <button
                                                                title="Delete Card"
                                                                onClick={() => {
                                                                    setConfirmStates((prevStates) => ({
                                                                        ...prevStates,
                                                                        [card.id]: !prevStates[card.id],
                                                                    }));
                                                                }}
                                                                className='border border-red-600 text-white text-xs bg-red-600 px-4 py-1 rounded hover:bg-red-800'
                                                            >
                                                                {deleteLoadingStates[card.id] ? 
                                                                    <div className="flex items-center gap-2 px-8">
                                                                        <div className='spinner-sm'></div>
                                                                    </div>
                                                                :
                                                                    "Delete Card"
                                                                }
                                                            </button>
                                                            <button
                                                                onClick={() => setDefaultPaymentMethod(card.id)}
                                                                className='border border-blue-500 text-xs bg-blue-300 px-4 py-1 rounded hover:bg-blue-500'
                                                            >
                                                                {loadingStates[card.id] ? 
                                                                    <div className="flex items-center gap-2 px-8">
                                                                        <div className='spinner-sm'></div>
                                                                    </div>
                                                                :
                                                                    "Set as default"
                                                                }
                                                            </button>
                                                        </div>
                                                    }
                                                    {card.id === defaultPaymentId &&
                                                        <p className='text-xs bg-green-400 py-1 px-4 rounded'>Default</p>
                                                    }
                                                </div>
                                                {confirmStates[card.id] && (
                                                    <div className='w-full flex flex-col justify-center items-center'>
                                                        <p className='mt-2 text-red-600'>This will delete your card permanently!</p>
                                                        <div className="w-full flex justify-center items-center gap-4 mt-4 font-bold">
                                                            <button
                                                                className='button-red'
                                                                title="Confirm Delete"
                                                                onClick={() => {
                                                                    deleteCard(card.id);
                                                                    setConfirmStates((prevStates) => ({
                                                                        ...prevStates,
                                                                        [card.id]: false,
                                                                    }));
                                                                }}
                                                            >
                                                                {deleteLoadingStates[card.id] ? 
                                                                    <div className="flex items-center gap-2 px-8">
                                                                        <div className='spinner-sm'></div>
                                                                    </div>
                                                                :
                                                                    "Confirm"
                                                                }
                                                            </button>
                                                            <button
                                                                className='button-transparent px-4 border border-black'
                                                                title="Cancel Delete"
                                                                onClick={() => {
                                                                    setConfirmStates((prevStates) => ({
                                                                        ...prevStates,
                                                                        [card.id]: false,
                                                                    }));
                                                                }}
                                                            >Cancel
                                                            </button>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                         {message && <div className="w-full text-center text-sm text-green-800 mt-2">{message}</div>}
                                    </div>
                                )}
                                
                            </div>

                            <div className="Payment  w-full">
                                <p className='font-bold text-lg uppercase'>Add Card:</p>
                                <Elements stripe={stripePromise} options={options}>
                                    <SetupForm />
                                </Elements>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <Copyright />
        </>
    );
}

export default Payment;
