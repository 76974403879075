import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, Link } from 'react-router-dom';
import DashboardHeader from "../components/Dashboard-Header";
import DashboardOptions0 from "../components/Dashboard-Options-0";
import Copyright from "../components/Copyright";
import PageNotFound from "../../../images/404.png"

import InvoiceDetails from "./components/InvoiceDetails";

function Invoices(userData) {
    const user = userData.user;
    const compartment = "dashboard";

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const invoiceID = queryParams.get('invoiceid');
    const detailsFlag = queryParams.has('details');
    const statusFilterFromURL = queryParams.get('status');  // Get status filter from the URL

    // console.log("INV: ", invoiceID);

    useEffect(() => {
        document.title = "Manage Invoices";
    }, []);

    const selectedItem0 = "invoices";

    const navigate = useNavigate();

    ////////////////////////////////////////////////////////
    // Demo invoice data
    const invoices = [
        { id: 709126, plan: "TD-s2-small", server: "server.tdshop.in", amount: 299, invoiceDate: "Dec 1, 2023", dateFrom: "Nov 1, 2023", dateTo: "Nov 30, 2023", dueDate: "Dec 10, 2023", datePaid: "Dec 4, 2023", status: "paid", gateWay: "razorpay", transactionID: "pay_OvVCmBIohTFwqDS" },
        { id: 981022, plan: "TD-s2-large", server: "server.turf.com", amount: 499, invoiceDate: "Dec 1, 2023", dateFrom: "Nov 1, 2023", dateTo: "Nov 30, 2023", dueDate: "Dec 10, 2023", datePaid: "", status: "unpaid", gateWay: "", transactionID: "" },
        { id: 354071, plan: "TD-s2-medium", server: "server.media.com", amount: 399, invoiceDate: "Dec 1, 2023", dateFrom: "Nov 1, 2023", dateTo: "Nov 30, 2023", dueDate: "Dec 10, 2023", datePaid: "Dec 4, 2023", status: "paid", gateWay: "razorpay", transactionID: "pay_OvVCmBIohTFwqDS" },
        { id: 409854, plan: "TD-s2-extra-large", server: "server.giant.com", amount: 599, invoiceDate: "Dec 1, 2023", dateFrom: "Nov 1, 2023", dateTo: "Nov 30, 2023", dueDate: "Dec 10, 2023", datePaid: "", status: "unpaid", gateWay: "", transactionID: "" },
        { id: 512394, plan: "TD-s2-micro", server: "server.micro.com", amount: 199, invoiceDate: "Dec 1, 2023", dateFrom: "Nov 1, 2023", dateTo: "Nov 30, 2023", dueDate: "Dec 10, 2023", datePaid: "Dec 4, 2023", status: "paid", gateWay: "paypal", transactionID: "pay_OvVCmBIohTFwqDS" },
        { id: 609365, plan: "TD-s2-nano", server: "server.nano.com", amount: 99, invoiceDate: "Dec 1, 2023", dateFrom: "Nov 1, 2023", dateTo: "Nov 30, 2023", dueDate: "Dec 10, 2023", datePaid: "", status: "unpaid", gateWay: "", transactionID: "" },
        { id: 798102, plan: "TD-s2-pico", server: "server.pico.com", amount: 89, invoiceDate: "Dec 1, 2023", dateFrom: "Nov 1, 2023", dateTo: "Nov 30, 2023", dueDate: "Dec 10, 2023", datePaid: "Dec 4, 2023", status: "paid", gateWay: "razorpay", transactionID: "pay_OvVCmBIohTFwqDS" },
    ];
    ////////////////////////////////////////////////////////

    const currentInvoice = invoiceID ? invoices.find(invoice => invoice.id === parseInt(invoiceID)) : null;

    const handleViewDetails = (invoiceid) => {
        navigate(`/dashboard/invoices?details&invoiceid=${invoiceid}`);
    };

    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const [filterStatus, setFilterStatus] = useState(statusFilterFromURL || 'all');  // Initialize with status from URL
    const [itemsPerPage, setItemsPerPage] = useState(5);

    // Filtered invoices based on search query
    const filteredInvoices = invoices.filter(invoice => {
        const matchesQuery =
            invoice.plan.toLowerCase().includes(searchQuery.toLowerCase()) ||
            invoice.server.toLowerCase().includes(searchQuery.toLowerCase()) ||
            `PCI-${invoice.id}`.toLowerCase().includes(searchQuery.toLowerCase());

        const matchesStatus =
            filterStatus === 'all' ||
            (filterStatus === 'paid' && invoice.status === 'paid') ||
            (filterStatus === 'unpaid' && invoice.status === 'unpaid');

        return matchesQuery && matchesStatus;
    });

    const totalPages = Math.ceil(filteredInvoices.length / itemsPerPage);

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    // const handlePreviousPage = () => {
    //     setCurrentPage((prev) => Math.max(prev - 1, 1));
    //     setInputPage(currentPage - 1); // Keep input field in sync
    // };

    // const handleNextPage = () => {
    //     setCurrentPage((prev) => Math.min(prev + 1, totalPages));
    //     setInputPage(currentPage + 1); // Keep input field in sync
    // };

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setCurrentPage(1); // Reset to first page when searching
    };

    const handleFilterChange = (event) => {
        const selectedStatus = event.target.value;
        setFilterStatus(selectedStatus);
        setCurrentPage(1); // Reset to first page when changing filter
        // Update the URL to reflect the selected filter
        navigate(`/dashboard/invoices?status=${selectedStatus}`);
    };

    // const handlePageChange = () => {
    //     const pageNumber = parseInt(inputPage, 10);
    //     if (pageNumber >= 1 && pageNumber <= totalPages) {
    //         setCurrentPage(pageNumber);
    //     } else {
    //         // Optionally, show an error or reset the input value
    //         setInputPage(currentPage);
    //     }
    // };

    // Calculate the invoices to display on the current page
    const startIndex = (currentPage - 1) * itemsPerPage;
    const selectedInvoices = filteredInvoices.slice(startIndex, startIndex + itemsPerPage);

    return (
        <>
            <DashboardHeader userData={user} selectedItem0={selectedItem0} compartment={compartment} />
            <div className="flex lg:flex-row flex-col">

                <div className="hidden lg:block lg:w-[27%]">
                    <DashboardOptions0 selectedItem0={selectedItem0} />
                </div>

                <div className="h-screen overflow-y-auto lg:w-[73%] bg-white">
                    <div className={`${invoiceID ? "lg:pr-0" : "lg:pr-20"} md:w-auto w-[230%] bg-white flex flex-col gap-2 m-5 md:shadow-custom3 rounded sm:pl-10 sm:pr-10 py-10 lg:pl-5 items-start`}>

                        {invoiceID ? (
                            <>
                                {currentInvoice && detailsFlag ? (
                                    <InvoiceDetails userData={user} invoiceDetails={currentInvoice} />
                                ) : (
                                    <div className="p-5 flex lg:flex-row-reverse flex-col justify-center items-center gap-16 lg:gap-8 text-black">

                                        <div className="w-full lg:w-[60%] flex justify-center items-center"><img className="w-full md:w-[80%] lg:w-full" src={PageNotFound} alt="" /></div>

                                        <div className="w-full lg:w-[40%]">
                                            <h1 className="text-[90px] font-bold text-center leading-14 text-custom-green">404<br />error</h1>
                                            <p className="text-sm text-center mt-10">It seems the page you were looking for has taken a detour or is on vacation. Don't worry, though – even the best explorers find themselves off the beaten path from time to time.</p>
                                            <Link className="mt-4 elastic-button1 px-6 py-1 cursor-pointer rounded" to="/dashboard/invoices"><i className="fa-solid fa-money-check-dollar"></i><span className="pl-4">Invoices</span></Link>
                                        </div>

                                    </div>
                                )}
                            </>
                        ) : (
                            <>
                                <div className="text-left w-full mb-6">
                                    <h1 className="uppercase mb-10 text-black text-xl font-bold text-left md:mt-10">Your Invoices</h1>
                                    <div className="flex w-full items-center justify-between">
                                        <div className="w-[78%] flex items-center">
                                            <input
                                                type="text"
                                                placeholder="Search by ID, Server, Plan"
                                                value={searchQuery}
                                                onChange={handleSearchChange}
                                                className="w-full px-4 py-2 pr-10 border border-gray-300 rounded-lg focus:outline-none focus:border-black focus:ring-black focus:ring-1"
                                                disabled={invoices.length === 0}
                                            />
                                            {/* <i className="fa-solid fa-x -ml-8 opacity-50 hover:opacity-80 cursor-pointer" title="clear" onClick={() => setSearchQuery("")}></i> */}
                                            <i className={`${searchQuery ? "hover:opacity-80 cursor-pointer" : ""} fa-solid fa-x -ml-8 opacity-50`} title={`${searchQuery ? "clear" : ""}`} disabled={!searchQuery} onClick={() => setSearchQuery("")}></i>
                                        </div>
                                        <select value={filterStatus} onChange={handleFilterChange} className="px-4 py-2 w-[20%] rounded-lg border border-gray-300">
                                            <option value="all">All</option>
                                            <option value="paid">Paid</option>
                                            <option value="unpaid">Unpaid</option>
                                        </select>
                                    </div>
                                </div>

                                {invoices.length === 0 ? (
                                    <div className="w-full flex justify-center items-center py-4 opacity-30">
                                        <p className="text-lg font-bold">No invoices to display</p>
                                    </div>
                                ) : (
                                    <>
                                        {selectedInvoices.length > 0 ? (
                                            selectedInvoices.map((invoice) => (
                                                <div key={invoice.id} className="w-full p-4 flex gap-2 items-center justify-between text-sm text-black border-b border-black border-opacity-20">
                                                    <div className="w-full flex justify-between items-center gap-2 flex-1">
                                                        <div className="flex-1 font-bold">
                                                            PCI-{invoice.id}
                                                        </div>
                                                        <div className="flex flex-1 flex-col items-start">
                                                            <h1 className="uppercase font-bold">{invoice.server}</h1>
                                                            <h2 className="uppercase font-light">{invoice.plan}</h2>
                                                        </div>
                                                        <div className="flex-1 flex flex-col items-center">
                                                            <h1 className="">Amount</h1>
                                                            <h2 className="uppercase font-bold">US$ {invoice.amount}</h2>
                                                        </div>
                                                    </div>
                                                    <div className="w-full flex justify-between gap-2 flex-1">
                                                        <div className="flex-1 flex flex-col items-center">
                                                            <h1 className="">Due Date</h1>
                                                            <h2 className="font-bold">{invoice.dueDate}</h2>
                                                        </div>
                                                        <div className="flex flex-1 justify-end gap-4 items-center">
                                                            <h1 className={`uppercase font-bold ${invoice.status === 'paid' ? 'text-green-700' : 'text-red-700'}`}>
                                                                {invoice.status}
                                                            </h1>
                                                            <button type="submit" onClick={() => handleViewDetails(invoice.id)} className="button-transparent border border-black">View</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        ) : (
                                            <p className="text-red-700 text-xs font-bold">No invoices found for your search.</p>
                                        )}
                                    </>
                                )}

                                <div className="flex justify-between mt-10 w-full">
                                    <button
                                        onClick={handlePreviousPage}
                                        disabled={currentPage === 1}
                                        className={`${currentPage === 1 ? "opacity-40" : "hover:text-custom-green"} flex items-center gap-1`}
                                    >
                                        <i className="fa-solid fa-arrow-left-long"></i>
                                        <p>Previous</p>
                                    </button>

                                    <div className="flex justify-center items-center gap-4">
                                        <div>
                                            {Array.from({ length: totalPages }, (_, index) => {
                                                const pageNumber = index + 1;
                                                return (
                                                    <button
                                                        key={pageNumber}
                                                        onClick={() => handlePageClick(pageNumber)}
                                                        className={`${currentPage === pageNumber ? 'text-custom-green font-bold' : 'text-black'} pr-1`}
                                                    >
                                                        {pageNumber}
                                                    </button>
                                                );
                                            })}
                                        </div>

                                        <div className="text-sm font-light flex justify-center items-center gap-1">
                                            <p>Per Page: </p>
                                            <input
                                                className="w-10 border border-black border-opacity-30 rounded py-[1px] px-1 no-arrows focus:outline-none focus:border-black focus:ring-black focus:ring-1"
                                                defaultValue={itemsPerPage} // Use defaultValue to let the user modify it
                                                type="number"
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        const value = e.target.value;
                                                        // Check if the input is a valid integer
                                                        if (value === '' || /^\d+$/.test(value)) {
                                                            // Update state only if the input is a valid number when Enter is pressed
                                                            setItemsPerPage(parseInt(value, 10) || 0); // Use parseInt to convert to a number
                                                        }
                                                    }
                                                }}
                                            />
                                        </div>

                                        <button className={`${totalPages <= 1 ? "cursor-not-allowed" : "hover:border-opacity-100"} px-1 py-[1px] text-sm font-light border border-black border-opacity-30 rounded`}
                                            onClick={() => {
                                                setItemsPerPage(invoices.length);
                                                setCurrentPage(1);
                                            }}
                                            disabled={totalPages <= 1}
                                        >
                                            All
                                        </button>

                                        <p className="text-sm">
                                            Showing {" "}
                                            {((currentPage - 1) * itemsPerPage) + 1 > invoices.length
                                                ? invoices.length
                                                : ((currentPage - 1) * itemsPerPage) + 1
                                            }-
                                            {(currentPage * itemsPerPage) > invoices.length 
                                                ? invoices.length 
                                                : (currentPage * itemsPerPage)
                                            } {" "}
                                            of {invoices.length} records.
                                        </p>
                                    </div>

                                    <button
                                        onClick={handleNextPage}
                                        disabled={currentPage === totalPages || totalPages <= 1}
                                        className={`${currentPage === totalPages || totalPages <= 1 ? "opacity-40" : "hover:text-custom-green"} flex items-center gap-1`}
                                    >
                                        <p>Next</p>
                                        <i className="fa-solid fa-arrow-right-long"></i>
                                    </button>
                                </div>

                                {/* <div className="flex justify-between mt-10 w-full">
                                    <button
                                        onClick={handlePreviousPage}
                                        disabled={currentPage === 1}
                                        className={`${currentPage === 1 ? "opacity-40" : "hover:text-custom-green"} flex items-center gap-1`}
                                    >
                                        <i className="fa-solid fa-arrow-left-long"></i>
                                        <p>Previous</p>
                                    </button>

                                    <div className="flex items-center gap-2">
                                        <span>Page</span>
                                        <input
                                            type="number"
                                            value={currentPage}
                                            min={1}
                                            max={totalPages}
                                            onChange={(e) => setInputPage(e.target.value)}
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                    handlePageChange();
                                                }
                                            }}
                                            className="border px-2 py-1 w-16 text-center rounded"
                                        />
                                        <span>/{totalPages}</span>
                                    </div>

                                    <button
                                        onClick={handleNextPage}
                                        disabled={currentPage === totalPages}
                                        className={`${currentPage === totalPages ? "opacity-40" : "hover:text-custom-green"} flex items-center gap-1`}
                                    >
                                        <p>Next</p>
                                        <i className="fa-solid fa-arrow-right-long"></i>
                                    </button>
                                </div> */}

                            </>
                        )}
                    </div>
                </div>
            </div>
            <Copyright />
        </>
    );
}

export default Invoices;
