import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import axios from "axios"
// import { useNavigate } from "react-router-dom";

// import companyLogo from '../../../images/logo.png';
import companyLogo from '../../../images/poorva-cloud-logo.png';

function Header(userData) {
    const user = userData.userData;
    const userImage = user && user.profileImage;
    // console.log(userImage);

    const [isMenuOpen, setMenuOpen] = useState(false);
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [isSigningOut, setIsSigningOut] = useState(false);
    const [bgColor, setBgColor] = useState('ai-bg text-white border-none p-[5px]'); // Initially blue

    useEffect(() => {
        const interval = setInterval(() => {
            setBgColor((prevColor) => prevColor === 'ai-bg text-white border-none p-[5px]' ? 'bg-white text-black border border-black p-[4px]' : 'ai-bg text-white border-none p-[5px]');
        }, 500); // Change color every 0.5 seconds

        // Cleanup the interval when the component is unmounted
        return () => clearInterval(interval);
    }, []);

    const toggleMenu = () => {
        setMenuOpen(!isMenuOpen);
    };

    const openDropdown = () => {
        setDropdownOpen(true);
    };

    const closeDropdown = () => {
        setDropdownOpen(false);
    };

    // const navigate = useNavigate()
    const handleSignOut = async () => {
        setIsSigningOut(true);
        closeDropdown();

        try {
            const signoutApiUrl = `${process.env.REACT_APP_PAYMENT_AUTHENTICATION_MAIL_API}/auth/signout/`;
            await axios.get(signoutApiUrl, { withCredentials: true, })
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        } catch (error) {
            // setError(error);
            console.log("An error occurred during signout: ", error);
        };   
    };
    
    return (
        <>
            {isSigningOut &&
                <div className="w-full h-screen fixed inset-0 z-50 bg-header flex justify-center items-center">
                    <img className="absolute top-20 w-[30%] lg:w-[12%] rounded" src={companyLogo} alt="" />
                    <div className="flex justify-center items-center gap-2">
                        <div className="spinner"></div>
                        <p className='font-bold'>Signing out...</p>
                    </div>
                </div>
            }
            <nav className="sticky top-0 z-40 bg-header w-full lg:py-1 py-4 lg:px-20 lg:flex justify-between px-4 text-black shadow">
                
                <div className="flex-1 flex justify-between items-center flex-row-reverse w-full">
                    
                    {/* CLIENT OPTIONS FOR SMALL DEVICES (TABLETS, MOBILES) START*/}
                    <div className="flex lg:hidden justify-end md:flex-1">
                        {/* <i className="cursor-pointer flex justify-center items-center fa-solid fa-user text-xl w-[45px] h-[45px] rounded-full border-2 border-color1 hover:scale-105" onClick={toggleDropdown}></i> */}
                        {userImage &&
                            <div className="cursor-pointer flex justify-center items-center w-[45px] h-[45px] rounded-full border-2 border-black hover:scale-105"
                                onMouseEnter={openDropdown}
                                onMouseLeave={closeDropdown}>
                                <img alt="" src={userImage} className="rounded-full" />
                            </div>
                        }
                        {!userImage &&
                            <i className="cursor-pointer flex justify-center items-center fa-solid fa-user text-xl w-[45px] h-[45px] rounded-full border-2 border-black hover:scale-105"
                                onMouseEnter={openDropdown}
                                onMouseLeave={closeDropdown}>
                            </i>
                        }
                        
                        <div className={isDropdownOpen ? "absolute mt-12 shadow-custom3 bg-header text-sm leading-8 px-8 py-4 rounded" : "hidden"}>
                            {user ? (
                                <ul className="font-normal text-left">
                                    {userImage &&
                                        <li className="cursor-auto font-bold py-2 flex items-center gap-4"><img alt="" src={userImage} className="w-8 h-8 rounded-full" /><p>{user.fullName}</p></li>
                                    }
                                    {!userImage &&
                                        <li className="cursor-auto font-bold py-2 flex items-center gap-4"><i className="text-2xl fa-solid fa-circle-user"></i><p>{user.fullName}</p></li>
                                    }
                                    <li className="hover:scale-105"><Link to="/user/profile" className="flex items-center gap-4 "><i className="fa-regular fa-address-book"></i><p>Profile</p></Link></li>
                                    <li className="hover:scale-105"><Link to="/dashboard" className="flex items-center gap-4 "><i className="fa-solid fa-table-columns"></i><p>Dashboard</p></Link></li>
                                    {/* <li className="hover:scale-105"><Link to="/profile" className="flex items-center gap-4 "><i className="fa-regular fa-rectangle-list"></i><p>Products</p></Link></li> */}
                                    <li className="hover:scale-105"><Link to="/user/settings" className="flex items-center gap-4 "><i className="fa-solid fa-gear"></i><p>Security Settings</p></Link></li>
                                    <li className="hover:scale-105"><Link to="/user/changepassword" className="flex items-center gap-4 "><i className="fa-solid fa-lock"></i><p>Change Password</p></Link></li>
                                    <li className="hover:scale-105"><button className="flex items-center gap-4 " onClick={handleSignOut} type="submit"><i className="fa-solid fa-right-from-bracket"></i><p>Sign Out</p></button></li>
                                </ul>
                            ) : (
                                <ul className="font-normal text-left">
                                    <li className="hover:scale-105"><Link to="/signin" className="flex items-center gap-4"><i className="fa-solid fa-right-to-bracket"></i><p>Login</p></Link></li>
                                    <li className="hover:scale-105"><Link to="/signup" className="flex items-center gap-3"><i className="fa-solid fa-user-plus"></i><p>Register</p></Link></li>
                                </ul>
                            )}
                        </div>
                    </div>
                    {/* CLIENT OPTIONS FOR SMALL DEVICES (TABLETS, MOBILES) END*/}
                    {/* MAIN MENU STARTS */}
                    <div className="md:flex-1 flex items-start justify-center lg:justify-start">
                        <Link className="w-[50%] lg:w-[40%] md:w-[50%] leading-4 cursor-pointer" to="/">
                            <img src={companyLogo} alt="Poorva Cloud" />
                        </Link>
                        <div className={`text-[8px] mt-[6px] rounded-[2px] ${bgColor}`}>AI Powered</div>

                        {/* <Link className="lg:w-[35%] md:w-[50%] w-[90%] leading-4 cursor-pointer flex justify-center items-center flex-col" to="/">
                            <div className="flex gap-1 justify-center items-center text-xl">
                                <p className="text-custom-green">Poorva</p>
                                <div className="p-1 bg-black text-white">Cloud</div>
                            </div>
                            <p className="text-xs">Managed Cloud Hosting</p>
                        </Link> */}

                    </div>
                    <div className="md:flex-1 lg:hidden flex justify-start" onClick={toggleMenu}>
                        <i className={isMenuOpen ? "cursor-pointer text-2xl fa-solid fa-x" : "cursor-pointer text-2xl fa-solid fa-bars"}></i>
                    </div>
                </div>
                
                <ul className={isMenuOpen ? "flex-1 leading-10 mt-[50px] font-bold text-[24px] uppercase py-4 text-center" : "hidden lg:flex flex-1 items-center gap-6"}>
                    <li className="cursor-pointer hover:text-slate-950 hover:scale-105 transition duration-150 ease-in"><Link to="https://turfdev.in/">Services</Link></li>
                    <li className="cursor-pointer hover:text-slate-950 hover:scale-105 transition duration-150 ease-in"><Link to="https://turfdev.in/">Solutions</Link></li>
                    <li className="cursor-pointer hover:text-slate-950 hover:scale-105 transition duration-150 ease-in"><Link to="#">Company</Link></li>
                    <li className="cursor-pointer hover:text-slate-950 hover:scale-105 transition duration-150 ease-in"><Link to="/plans">Pricing</Link></li>
                </ul>
                {/* MAIN MENU ENDS */}

                {/* CLIENT OPTIONS FOR LARGE DEVICES (PCs, LAPTOPS) START*/}
                <div className="flex-1 hidden lg:flex items-center justify-center text-center gap-2 lg:justify-end">
                    {!user &&
                        <div className="flex gap-2">
                            <Link to="/signin" className="button1">
                                Login
                            </Link>
                            <Link to="/signup" className="button2">
                                Register
                            </Link>
                        </div>
                    }
                    
                    {user &&
                        <div className="" onMouseEnter={openDropdown} onMouseLeave={closeDropdown}>
                            
                            {userImage &&
                                <img alt="" src={userImage} className="cursor-pointer flex justify-center items-center w-[45px] h-[45px] rounded-full hover:scale-105" />
                            }
                            {!userImage &&
                                <li className="cursor-pointer font-bold flex items-center rounded-full text-custom-green"><i className="text-[45px] hover:scale-105 fa-solid fa-circle-user"></i></li>
                            }
                            
                            <div className={isDropdownOpen ? "absolute -ml-[170px] shadow-custom3 bg-header font-normal text-sm leading-8 px-8 py-4 rounded" : "hidden"}>
                                <ul className="text-left">
                                    {userImage &&
                                        <li className="flex items-center gap-4 cursor-auto font-bold py-2 text-custom-green"><img alt="" src={userImage} className="w-8 h-8 rounded-full" /><p>{user.fullName}</p></li>
                                    }
                                    {!userImage &&
                                        <li className="flex items-center gap-4 cursor-auto font-bold py-2 text-custom-green"><i className="text-2xl fa-solid fa-circle-user"></i><p>{user.fullName}</p></li>
                                    }
                                    <li className="hover:scale-105"><Link to="/user/profile" className="flex items-center gap-4 "><i className="fa-regular fa-address-book"></i><p>Profile</p></Link></li>
                                    <li className="hover:scale-105"><Link to="/dashboard" className="flex items-center gap-4 "><i className="fa-solid fa-table-columns"></i><p>Dashboard</p></Link></li>
                                    {/* <li className="hover:scale-105"><Link to="/profile" className="flex items-center gap-4 "><i className="fa-regular fa-rectangle-list"></i><p>Products</p></Link></li> */}
                                    <li className="hover:scale-105"><Link to="/user/settings" className="flex items-center gap-4 "><i className="fa-solid fa-gear"></i><p>Security Settings</p></Link></li>
                                    <li className="hover:scale-105"><Link to="/user/changepassword" className="flex items-center gap-4 "><i className="fa-solid fa-lock"></i><p>Change Password</p></Link></li>
                                    <li className="hover:scale-105"><button className="flex items-center gap-4 " onClick={handleSignOut} type="submit"><i className="fa-solid fa-right-from-bracket"></i><p>Sign Out</p></button></li>
                                </ul>
                            </div>
                        </div>
                    }
                    {/* CLIENT OPTIONS FOR LARGE DEVICES (PCs, LAPTOPS) END*/}

                </div>
                
            </nav>
        </>
    )

}

export default Header;