import React, {useEffect} from "react";
import { Link } from 'react-router-dom';

import Header from "../components/Header";
import Footer from "../components/Footer";

import { serverSizes } from '../../open-routes/components/ServerSizes';

function AllPlans(userData) {
    useEffect(() => {
        document.title = "Choose a plan that suits your needs from a diverse range.";
    }, []);

    const user = userData.user;

    return (
        <>
            <Header userData={user}/>
            {/* HERO SECTION STARTS */}

            <div className="bg-white text-black px-5 py-20 md:px-20 md:py-36 text-center">
                <h1 className="md:text-[70px] text-[50px] font-bold leading-tight tracking-tighter mb-5">Our <span className="text-custom-green">Managed Cloud Server</span> Plans</h1>
                <p><span className="font-bold">Welcome to Poorva Cloud,</span> your trusted partner for premium Managed Linux Server hosting. <br />We offer a range of server plans to cater to your specific needs, ensuring optimal performance, security, and support. <br />Explore our server plans below and find the perfect solution for your business.</p>
            </div>

            {/* HERO SECTION ENDS */}

            {/* <div className="p-5 md:pt-20 md:px-20 bg-color4 text-color1">
                <div className="w-full shadow-custom3 p-10 rounded-3xl flex flex-col justify-center items-center">
                    <h1 className="text-xl font-light">Create your own custom server or choose from our predefinded servers</h1>
                    <div className="mt-5">
                        <Link to="/createserver" className="uppercase text-sm py-2 border-2 border-color1 px-8 rounded-full hover:bg-color1 hover:text-color4">Create Custom Server</Link>
                    </div>
                </div>
            </div> */}

            <div className="flex lg:flex-row flex-col-reverse lg:gap-5 gap-20 md:px-10 md:py-20 p-5 bg-white text-black">

                <div className="lg:w-[25%] w-full text-white bg-custom-green px-5 py-10 rounded-xl">
                    <h1 className="text-center lg:text-left text-xl font-bold mb-10">All Plans Come With:</h1>

                    <div className="flex flex-col gap-4">
                        <ul className="flex flex-col flex-1 gap-4 font-light text-sm">
                            <li className="flex gap-4 items-center"><i className="fa-regular fa-circle-check"></i>24/7/365 Support</li>
                            <li className="flex gap-4 items-center"><i className="fa-regular fa-circle-check"></i>Free SSL</li>
                            <li className="flex gap-4 items-center"><i className="fa-regular fa-circle-check"></i>Cloudflare Add-on</li>
                            <li className="flex gap-4 items-center"><i className="fa-regular fa-circle-check"></i>Free Migration</li>
                        </ul>

                        <ul className="flex flex-col flex-1 gap-4 font-light text-sm">
                            <li className="flex gap-4 items-center"><i className="fa-regular fa-circle-check"></i>Unlimited Application Installation</li>
                            <li className="flex gap-4 items-center"><i className="fa-regular fa-circle-check"></i>Dedicated Firewalls</li>
                            <li className="flex gap-4 items-center"><i className="fa-regular fa-circle-check"></i>24/7 Real-time Monitoring</li>
                            <li className="flex gap-4 items-center"><i className="fa-regular fa-circle-check"></i>Automated Backups</li>
                        </ul>

                        <ul className="flex flex-col flex-1 gap-4 font-light text-sm">
                            <li className="flex gap-4 items-center"><i className="fa-regular fa-circle-check"></i>Staging Environment</li>
                            <li className="flex gap-4 items-center"><i className="fa-regular fa-circle-check"></i>Optimized With Advanced Caches</li>
                            <li className="flex gap-4 items-center"><i className="fa-regular fa-circle-check"></i>Auto Healing</li>
                            <li className="flex gap-4 items-center"><i className="fa-regular fa-circle-check"></i>Regular Security Patching</li>
                        </ul>

                        <ul className="flex flex-col flex-1 gap-4 font-light text-sm">
                            <li className="flex gap-4 items-center"><i className="fa-regular fa-circle-check"></i>Free Object Cache Pro (2GB & above)</li>
                            <li className="flex gap-4 items-center"><i className="fa-regular fa-circle-check"></i>HTTP/2 Enabled Servers</li>
                            <li className="flex gap-4 items-center"><i className="fa-regular fa-circle-check"></i>SSH and SFTP Access</li>
                            <li className="flex gap-4 items-center"><i className="fa-regular fa-circle-check"></i>Team Management</li>
                        </ul>
                    </div>

                </div>

                <div className="lg:w-[75%] overflow-x-auto">
                    <div className="w-[270%] sm:w-[130%] lg:w-full">

                        <div className="p-5 flex justify-start items-center font-light text-sm shadow">
                            <div className="flex-1 flex gap-2 items-center justify-start"><i className="text-custom-green fa-solid fa-clipboard-list"></i>Plan</div>
                            <div className="flex-1 flex gap-2 items-center justify-start"><i className="text-custom-green fa-solid fa-memory"></i>RAM</div>
                            <div className="flex-1 flex gap-2 items-center justify-start"><i className="text-custom-green fa-solid fa-microchip"></i>Processor</div>
                            <div className="flex-1 flex gap-2 items-center justify-start"><i className="text-custom-green fa-solid fa-hard-drive"></i>Storage</div>
                            <div className="flex-1 flex gap-2 items-center justify-start"><i className="text-custom-green fa-solid fa-wifi"></i>Bandwidth</div>
                            <div className="flex-1 flex gap-2 items-center justify-start"><i className="text-custom-green fa-solid fa-tags"></i>Price</div>
                            <div className="flex-1 flex gap-2 items-center justify-start"></div>
                        </div>

                        <div className="overflow-auto h-screen">

                        {serverSizes.map((server, index) => (
                            <div key={index} className="p-5 flex justify-start items-center border border-custom-green rounded-xl my-5  text-xs">
                                <div className="flex-1 text-left ">{server.name}</div>
                                <div className="flex-1 text-left ">{server.details.Memory}</div>
                                <div className="flex-1 text-left ">{server.details.Core}</div>
                                <div className="flex-1 text-left ">20 GB</div>
                                <div className="flex-1 text-left ">2 GB</div>
                                <div className="flex-1 text-left "><span className="text-md lg:text-base font-bold text-custom-green">{server.price.Monthly}</span> USD/mo</div>
                                <div className="flex-1 text-right">
                                    <Link to={`/createserver?selectedServer=${index + 1}`}>
                                        <i className="text-custom-green cursor-pointer hover:scale-110 hover:text-green-900 fa-solid fa-arrow-right-long"></i>
                                    </Link>
                                </div>
                            </div>
                        ))}

                        </div>

                    </div>
                </div>
            </div>

            {/* <div className="bg-color4 flex justify-center p-5 md:pb-20 md:px-20">
                <div className="w-full text-color1 lg:p-10 p-5 border border-color3">
                    <h1 className="text-center text-md font-light mb-10">All Plans Come With:</h1>

                    <div className="flex flex-wrap flex-col md:flex-row gap-4">
                        <ul className="flex flex-col flex-1 gap-4 font-light text-sm">
                            <li className="flex gap-4 items-center"><i className="text-green-600 fa-regular fa-circle-check"></i>24/7/365 Support</li>
                            <li className="flex gap-4 items-center"><i className="text-green-600 fa-regular fa-circle-check"></i>Free SSL</li>
                            <li className="flex gap-4 items-center"><i className="text-green-600 fa-regular fa-circle-check"></i>Cloudflare Add-on</li>
                            <li className="flex gap-4 items-center"><i className="text-green-600 fa-regular fa-circle-check"></i>Free Migration</li>
                        </ul>

                        <ul className="flex flex-col flex-1 gap-4 font-light text-sm">
                            <li className="flex gap-4 items-center"><i className="text-green-600 fa-regular fa-circle-check"></i>Unlimited Application Installation</li>
                            <li className="flex gap-4 items-center"><i className="text-green-600 fa-regular fa-circle-check"></i>Dedicated Firewalls</li>
                            <li className="flex gap-4 items-center"><i className="text-green-600 fa-regular fa-circle-check"></i>24/7 Real-time Monitoring</li>
                            <li className="flex gap-4 items-center"><i className="text-green-600 fa-regular fa-circle-check"></i>Automated Backups</li>
                        </ul>

                        <ul className="flex flex-col flex-1 gap-4 font-light text-sm">
                            <li className="flex gap-4 items-center"><i className="text-green-600 fa-regular fa-circle-check"></i>Staging Environment</li>
                            <li className="flex gap-4 items-center"><i className="text-green-600 fa-regular fa-circle-check"></i>Optimized With Advanced Caches</li>
                            <li className="flex gap-4 items-center"><i className="text-green-600 fa-regular fa-circle-check"></i>Auto Healing</li>
                            <li className="flex gap-4 items-center"><i className="text-green-600 fa-regular fa-circle-check"></i>Regular Security Patching</li>
                        </ul>

                        <ul className="flex flex-col flex-1 gap-4 font-light text-sm">
                            <li className="flex gap-4 items-center"><i className="text-green-600 fa-regular fa-circle-check"></i>Free Object Cache Pro (2GB & above)</li>
                            <li className="flex gap-4 items-center"><i className="text-green-600 fa-regular fa-circle-check"></i>HTTP/2 Enabled Servers</li>
                            <li className="flex gap-4 items-center"><i className="text-green-600 fa-regular fa-circle-check"></i>SSH and SFTP Access</li>
                            <li className="flex gap-4 items-center"><i className="text-green-600 fa-regular fa-circle-check"></i>Team Management</li>
                        </ul>
                    </div>
                </div>
            </div> */}

            <Footer />
        </>
    )
}

export default AllPlans;