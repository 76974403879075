import React from "react";
// import { Link } from 'react-router-dom';

import Header from "../components/Header";
import Footer from "../components/Footer";

function Privacy(userData) {
    const user = userData.user;

    return (
        <>
            <Header userData={user} />

            <div className="px-5 py-20 md:px-20 md:py-32 text-center">
                <h1 className="md:text-[76px] text-[48px] font-bold leading-tight tracking-tighter mb-5">Know the <span className="text-custom-green">Rules</span>, Enjoy the <span className="text-custom-green">Ride</span></h1>
                <p>Explore the terms that keep our services smooth and secure</p>
            </div>

            <div className="flex justify-center items-center bg-custom-green md:p-20 p-5">
                <div className="md:w-[80%] md:p-10 p-5 bg-white flex flex-col gap-5 rounded-3xl shadow-custom3">

                    <h1 className="text-3xl md:text-[36px] font-bold">Terms of Service</h1>
                    <p className="mb-10 text-sm">Last Updated on 27th Sep 2024</p>
                    
                    <div className="text-sm leading-6">
                        <h3 className="text-base font-bold mb-6">DEFINITIONS</h3>
                        <p>“We”/”Us”/”Our”/”Turf Dev Pvt. Ltd.”/”Poorva Cloud” means Turf Dev Pvt. Ltd. and/or any of its parents, subsidiaries and/or affiliates involved in providing the Services.</p>
                        <p className="mt-4">“You”/”Your”/”Yourself”/”User” means the user of the Website and/or customer of the Services.</p>
                        <p className="mt-4">“End Users” means any individual or entity that directly or indirectly through another user (a) accesses or uses Your Services Content, or (b) otherwise accesses or uses the Services under your account.</p>
                        <p className="mt-4">“Cloud Provider” means one or more third party cloud providers that may be used to provide the Services.</p>
                        <p className="mt-4">“Services” means any services offered by Turf Dev Pvt. Ltd. on its Website including but not limited to cloud application deployment, management and infrastructure management – commonly referred to as the ‘Poorva Cloud Platform’.</p>
                        <p className="mt-4">“Services Content” means source code, files, software, processes, interfaces, data, text, settings, media, or other information for storage, hosting, or processing by the Services.</p>
                        <p className="mt-4">“User Content” means content which you may submit or upload content, including messages, reviews, media, images, folders, data, text, and other types of work to the Services or as part of the Services.</p>
                        
                        <h3 className="text-base font-bold mt-10 my-6">PRELIMINARY</h3>
                        <p>These terms of service (the “Terms”, “Terms of Use”, or “Agreement) relate to your use of the Services provided by Turf Dev Pvt. Ltd. under the domain name poorva.cloud (“the Website”).</p>
                        <p className="mt-4">We have the right to revise and amend these Terms and/or the Services from time to time to reflect changes in market conditions affecting our business, changes in technology, changes in payment methods, changes in relevant laws and regulatory requirements and changes in our system’s capabilities or for any other reason in our sole discretion which we may decide. Your continued access to or use of the Website and/or the Services constitutes your acceptance of any such change and/or amendment.</p>

                        <h3 className="text-base font-bold mt-10 my-6">IN USING THE WEBSITE AND/OR THE SERVICES, YOU AGREE AS FOLLOWS,</h3>
                        <p>Acceptance of the Terms. Your continued access to or use of the Website and/or the Services constitutes your acceptance of our Acceptable Use Policy, of our Privacy Policy, of our SLA and these Terms. Certain Services may require additional terms and conditions in which case your clicking of “I ACCEPT” buttons and/or other prompts and/or any other similar methods of acquiring your consent will constitute your acceptance of the said terms and conditions.</p>

                        <h3 className="text-base font-bold mt-10 my-6 uppercase">1. Eligibility & Registration</h3>
                        <p>1.1 Before you use our Website and Services, you must ensure that such use is in compliance with all laws, rules and regulations applicable to you. Your right to access the Website and Services is revoked to the extent your use thereof is prohibited or to the extent our provision thereof conflicts with any applicable law, rule or regulation. You are responsible for making these determinations before using the Website and Services.</p>
                        <p className="mt-4">1.2 The Website and Services are not targeted towards, nor intended for use by, anyone under the age of 18. By using the Website and Services, you represent and warrant to us that: (a) you are 18 years of age or older; or (b) you otherwise have sufficient legal consent, permission and capacity to use the Website and Services in applicable jurisdiction(s) as determined by you.</p>
                        <p className="mt-4">1.3 To access the Services and some features of the Website, you must register for an account (“Account”). When you register for your Account, you may be required to provide us with some information about yourself, such as your name, email address, and a valid form of payment, and you may also provide optional information about yourself on a voluntary basis. Account information, and our use and disclosure thereof, is subject to the Privacy Policy and Data Processing Agreement.</p>
                        <p className="mt-4">1.4 We may, in our sole discretion, refuse to provide or continue providing the Website and Services to any person or entity and change eligibility criteria at any time, including if you fail to comply with these Terms of Use. We reserve the right to deactivate, terminate, prevent access to, disable services for, and/or delete any Accounts or access to the Website and Services at any time at our sole discretion.</p>

                        <h3 className="text-base font-bold mt-10 my-6 uppercase">2. Rules of Conduct/Use of the Services</h3>
                        <p>2.1 As a client of Turf Dev Pvt. Ltd. you agree to uphold the license / user agreements of any such cloud providers and application providers to the extent it applies to your use of any services offered by Turf Dev Pvt. Ltd. Please also read any cloud services and applications license agreements as may be applicable. As a user of Turf Dev Pvt. Ltd. services, you agree to uphold the terms of any such cloud services and applications license agreement to whatever extent they apply to your usage of cloud services, applications and/or Services.</p>
                        <p className="mt-4">2.2 <span className="font-bold">PLEASE KEEP IN MIND THAT SOME OF THE CONTENT THAT WE MAKE AVAILABLE TO YOU THROUGH POORVA CLOUD SERVICES MAY ORIGINATE FROM THIRD PARTY CLOUD SERVICES AND APPLICATIONS. ALL SUCH THIRD PARTY CONTENT IS PROVIDED TO YOU “AS IS.” AND YOUR USE THEREOF MAY BE SUBJECT TO CHANGE AND/OR REMOVAL AT ANY TIME BEYOND THE CONTROL OF TURF DEV PVT. LTD. TURF DEV PVT. LTD., SHALL UNDER NO CIRCUMSTANCES WHATSOEVER ACCEPT LIABILITY RESULTING FROM YOUR USE OF ANY SUCH THIRD PARTY SERVICES AND/OR CONTENT.</span></p>
                        <p className="mt-4">2.3 You must provide accurate and complete registration information any time you register to use any of the Services. You are responsible for the security of your passwords and for any use of your account. If you become aware of any unauthorized use of your password or of your account, you agree to notify Turf Dev Pvt. Ltd. immediately. Accordingly, you agree that you will be solely responsible for all activities which occur under your account.</p>
                        <p className="mt-4">2.4 You agree to use the application only for purposes that are permitted by (a) the Terms and (b) any applicable law, regulation or generally accepted practices or guidelines in the relevant jurisdictions (including any laws regarding the export of data or software to and from India or other relevant countries).</p>
                        <p className="mt-4">2.5. You may neither share nor re-sell your Turf Dev Pvt. Ltd. account to any third parties.</p>
                        <p className="mt-4">2.6. Unless you have been specifically permitted to do so in a separate agreement with Turf Dev Pvt. Ltd., you agree that you will not reproduce, duplicate, copy, sell, trade or resell the Services for any purpose.</p>
                        <p className="mt-4">2.7 You agree not to engage in any activity that interferes with or disrupts the Services (or the servers and networks which are connected to the Services). You are solely responsible for your Services Content, End Users and any activity by your End Users, and you agree Poorva Cloud is not and will not be in any way liable for your Services Content, End Users and/or activity by your End Users. By providing your Services Content via the Services, you affirm, represent, and warrant that:</p>
                            <ul className="list-disc flex flex-col gap-2 ml-4 mt-4">
                                <li>Your Services Content, and your or your End Users’ use of your Services Content, will not violate this agreement (including the AUP) or any applicable law, regulation, rule, or third party rights;</li>
                                <li>You are solely responsible for the development, moderation, operation, maintenance, support and use of your Services Content, including when your Services Content is contributed by your End Users;</li>
                                <li>Your Services Content, and your or your End Users’ use of your Services Content, does not and will not: (i) infringe, violate, or misappropriate any third party right, including any copyright, trademark, patent, trade secret, moral right, privacy right, right of publicity, or any other intellectual property or proprietary right; (ii) slander, defame, libel, or invade a right of privacy, publicity or other property rights of any other person; or (iii) cause us to violate any law, regulation, rule, or rights of third parties; and</li>
                                <li>Except for the specific Services provided to you under these Terms of Use or other express contract, you are solely responsible for the technical operation of your Services Content, including on behalf of your End Users.</li>
                            </ul>
                        <p className="mt-4">2.8 You are solely responsible for the activity that occurs on your Account, regardless of whether the activities are undertaken by you, your employees, any third party (including your contractors or agents), your End Users, your licensees, or your customers. You are responsible for notifying your employees, agents, and others related to your use of the Website and Services of the provisions of these Terms of Use, including where the terms of these Terms of Use are binding on them.</p>
                        <p className="mt-4">2.9 Any license to use any third-party applications is granted directly from the original provider of the third-party application(s) directly to you. You agree to abide by all the terms and conditions of any such license. You will be solely responsible for paying for and obtaining the rights to any third-party applications you install and/or operate through and/or in connection with the Services, and for compliance with the applicable terms and conditions of each third-party application license.</p>
                        <p className="mt-4">The Website and Services may include links to third party websites, services or other resources on the Internet, and third party websites, services or other resources may include links to our Websites and Services as well. When you access third party resources on the Internet, you do so at your own risk. These third party resources are not under our control, and, to the fullest extent permitted by law, we are not responsible or liable for the content, functions, accuracy, legality, appropriateness or any other aspect of such resources. The inclusion of any such link does not imply our endorsement or any association between us and any third party. To the fullest extent permitted by law, we shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such third party resource.</p>
                        <p className="mt-4">2.10 Poorva Cloud does not promise to retain any preservations or backups of your Services Content. You are solely responsible for the integrity, preservation and backup of your Services Content, regardless of whether your use of Services includes a Poorva Cloud backup feature or functionality, and to the fullest extent permitted by law, Poorva Cloud shall have no liability for any data loss, unavailability, or other consequences related to the foregoing.</p>
                        <p className="mt-4">2.11 When purchasing Services, you may specify the geographic region, Cloud Provider, and jurisdiction in which your Services Content will be stored. You consent to the storage of your Services Content in, and transfer of your Services Content into, the geographic region, jurisdiction, and Cloud Provider you select. Unless otherwise agreed in writing between you and Poorva Cloud, you agree that Poorva Cloud may transfer and store your Services Content in other geographic regions or jurisdictions and with other Cloud Providers at its sole discretion. You represent and warrant that such storage and transfer are adequately consented to by you and permissible pursuant to applicable laws, regulations, rules, and third party rights.</p>
                        <p className="mt-4">You agree to abide by the customer agreement, terms of service, acceptable use policy (AUP), and any other applicable terms and conditions of each third-party Cloud Provider that you use through the Services (if any). Those terms are incorporated to this agreement by reference and may be updated from time to time.</p>
                        <p className="mt-4">2.12. Some of the Services may require that you establish your own account with one or more third party cloud providers (“Cloud Provider). You will be solely responsible for paying for and establishing an account (“Customer Cloud Provider Account”) with each third-party Cloud Provider you select, and for compliance with the terms of use applicable thereto. You must provide Turf Dev Pvt. Ltd. with your Customer Cloud Provider Account credentials (the “CP Key”) that are necessary for Turf Dev Pvt. Ltd. to access and manage your use of the third-party Cloud Provider servers, including, without limitation, accessing servers as necessary to identify or resolve technical problems or to respond to any issues related to or connected with the Services. Turf Dev Pvt. Ltd. will use reasonable efforts to maintain the confidentiality and security of your CP Keys.</p>
                        <p className="mt-4">2.13. Turf Dev Pvt. Ltd. has also established its own accounts (“Poorva Cloud Provider Accounts”) with one or more third-party Cloud Providers, which Turf Dev Pvt. Ltd. may use to provide you with the Services. Turf Dev Pvt. Ltd. will be responsible for paying for and establishing the account with such Cloud Providers, provided that you will be responsible for paying Turf Dev Pvt. Ltd. the portion of the Cloud Provider fees allocated to you (of which you will be notified in advance), and for compliance with the terms of use applicable thereto.</p>
                        <p className="mt-4">2.14 We reserve the right to access, read, preserve, and disclose any information as we reasonably believe is necessary to (i) respond to any applicable law, regulation, legal process or governmental request; (ii) enforce this TOS, including investigation of potential violations hereof; (iii) detect, prevent, or otherwise address fraud, security or technical issues; (iv) respond to user support requests; or (v) protect the rights, property or safety of us, our users and the public.</p>
                        <p className="mt-4">2.15 The Services are subject to the trade and economic sanctions maintained by the Office of Foreign Assets Control (“OFAC”). By accessing the Services, you agree to comply with these laws and regulations. Specifically, you represent and warrant that you are not (a) located in any country that is subject to OFAC’s trade and economic sanctions, currently Cuba, Iran, North Korea, Syria, and the Crimea region of Ukraine; or (b) an individual or entity included on any U.S. lists of prohibited parties including: the Treasury Department’s List of Specially Designated Nationals List (“SDN List”) and Sectoral Sanctions List (“SSI List”). Additionally, you agree not to – directly or indirectly – sell, export, reexport, transfer, divert, or otherwise dispose of any service received from Poorva Cloud in contradiction with these laws and regulations. Failure to comply with these laws and regulations may result in the suspension or termination of your Account.</p>
                        <p className="mt-4">2.16. Turf Dev Pvt. Ltd. may offer (or act as intermediary in the offering of) certain application trials including the ability for you to install the said applications on a temporary basis for trial purposes. YOU ACKNOWLEDGE THAT THESE SERVERS AND APPLICATIONS ARE INTENDED TO BE TEMPORARY INSTALLATIONS AND THAT Turf Dev Pvt. Ltd. MAY DELETE APPLICATIONS AND SHUT DOWN RELEVANT SERVERS AT ANY TIME.</p>
                        
                        <h3 className="text-base font-bold mt-10 my-6">3. WARRANTY, LIMITATION OF LIABILITY, AND INDEMNIFICATION</h3>
                        <p>3.1 The Websites and Services, including without limitation any content delivered as a part thereof, are provided “as is” and on an “as available” basis. Poorva Cloud disclaims all warranties of any kind, whether express or implied, relating to the Websites and Services and all content delivered in connection thereto, including but not limited to: (a) any implied warranty of merchantability, fitness for a particular purpose, title, quiet enjoyment, or non-infringement; (b) any warranty arising out of course of dealing, usage, or trade; or (c) any warranty or guaranty relating to availability, accuracy, error rate, system integrity, or uninterrupted access. We do not warrant that: (i) the Websites or Services will be secure or available at any particular time or location; (ii) any defects or errors will be corrected; (iii) any content or software available at or through the Websites or Services is free of viruses or other harmful components; or (iv) the results of using the Websites or Services will meet your requirements. Your use of the Websites and Services is solely at your own risk.</p>
                        <p className="mt-4">3.2 Poorva Cloud is not responsible for the content that you access through your use of the Websites, Services, or content of other users of Poorva Cloud or other third parties. To the extent permitted by applicable law, you release us from all liability relating to such content. You acknowledge and agree that we make no representations concerning any content contained in or accessed through the Websites or content of other users of Poorva Cloud or other third parties, and that we are not responsible or liable for the accuracy, quality, legality, or other attributes of such content.</p>
                        <p className="mt-4">3.3 The limitations, exclusions, and disclaimers in this Section apply to the fullest extent permitted by law. Poorva Cloud does not disclaim any warranty or other right that Poorva Cloud is prohibited from disclaiming under applicable law.</p>
                        <p className="mt-4">3.4 To the fullest extent permitted by law, under no circumstances and under no legal theory, whether tort (including negligence), contract, or otherwise, shall Poorva Cloud or any other contributor or supplier of Poorva Cloud, be liable to any person for any indirect, special, incidental, or consequential damages of any kind including, without limitation, damages for loss of goodwill, work stoppage, interruption of the services. computer failure or malfunction, loss of data, or any and all other commercial damages or losses, even if such party shall have been informed of the possibility of such damages. You further understand and agree that while every reasonable precautions shall be undertaken by Turf Dev Pvt. Ltd., we are not /shall not be responsible for any loss or damage incurred by you, including but not limited to loss or damage as a result of:</p>
                            <ul className="list-disc flex flex-col gap-2 ml-4 mt-4">
                                <li>Any changes to the Services made by Turf Dev Pvt. Ltd., or any temporary or permanent cessation in the provision of the Services (or any features within the Services),</li>
                                <li>The deletion of, corruption of, or failure to store, any content and other communications data maintained or transmitted by or through your use of service,</li>
                                <li>Interruption of services, malware (including viruses and/or Trojans) and/or other errors over which Turf Dev Pvt. Ltd. has no control,</li>
                                <li>Any third party services and/or content of any kind. You understand that any hyperlinks to third party websites shall be regulated by the terms and conditions of third parties and that access to/use of such third party website shall be at your own risk.</li>
                            </ul>
                        <p className="mt-4">Without prejudice to the foregoing, should a court of law or other adjudicating body still find against Turf Dev Pvt. Ltd. regarding any matter relating to the Services, Turf Dev Pvt. Ltd.’s maximum liability against the claimant shall not exceed the total amount in fees which the claimant was paying/shall pay for one month of the Services in question.</p>
                        <p className="mt-4">3.5 To the fullest extent permitted by law, you are responsible for your use of the Website and Services, and you shall defend, indemnify, and hold harmless us and our employees, officers, directors, Affiliates, parent companies, agents, contractors, and representatives from all liabilities, claims, and expenses, including reasonable attorneys’ fees and costs, that arise from or relate to your Services Content, User Content, your End Users, or your access to or use of the Website and Services, including your breach of these Terms or applicable law, willful misconduct, negligence, illegal activity, breach of security or data, unauthorized access to or use of your Account, or infringement of a third party’s right, including any intellectual property, confidentiality, property or privacy right. We reserve the right to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, without limiting your indemnification obligations with respect to that matter, in which event you will make best efforts to assist and cooperate with us in defending the matter at your expense.</p>

                        <h3 className="text-base font-bold mt-10 my-6">4. MUTUAL CONFIDENTIALITY</h3>
                        <p>4.1 To the extent you receive or possess any nonpublic information from us that is designated confidential or, given the nature of the information or circumstances surrounding its disclosure, reasonably should be understood to be confidential, such information is our “Confidential Information” and must be handled according to these Terms. Confidential Information includes but is not limited to: (a) nonpublic information about our technology, customers, business plans, marketing and sales activities, finances, operations and other business information; and (b) the existence and content of our discussions or negotiations with you regarding your Account and/or use of the Services. Confidential Information does not include any information that: (i) is or becomes publicly available without breach of these Terms; (ii) can be shown by documentation to have been known to you at the time of your receipt from us; (iii) is received from a third party who did not acquire or disclose the information by a wrongful or tortious act or by violating the rights of us or third parties; or (iv) can be shown by documentation to have been independently developed by you without reference to the Confidential Information.</p>
                        <p className="mt-4">4.2 You may not use Confidential Information except in connection with your use of the Service as permitted under these Terms or as separately authorized in a signed writing by us. You agree to not disclose Confidential Information during the term of these Terms, after you cease using the Services and after these Terms otherwise no longer apply. You will take all reasonable measures to avoid disclosure or unauthorized use of Confidential Information, including at least the measures you take to protect your own confidential information of a similar nature.</p>
                        
                        <h3 className="text-base font-bold mt-10 my-6">5. GENERAL LEGAL TERMS</h3>
                        <p>5.1. Turf Dev Pvt. Ltd. may make changes to the Terms from time to time. Poorva Cloud reserves the right to update, change or otherwise modify these Terms on a going-forward basis at any time and in Poorva Cloud’ sole discretion. If Poorva Cloud updates these Terms or any other terms on its website, Poorva Cloud may provide notice of such updates, such as by sending an email notification to the email address listed in your Account and/or updating the “Last Updated” date at the beginning of these Terms. Updates will be effective on the date specified in the notice. By continuing to access or use the Website or Services after the date specified in the notice or updated Terms, you are agreeing to accept and be bound by the updated Terms and all of the terms incorporated therein. If you do not agree to the updated Terms, then you may not continue to use the Website or Services and your sole and exclusive remedy is to deactivate your Account and otherwise discontinue use of the Websites and/or Services.</p>
                        <p className="mt-4">5.2 While we prefer to give advance notice of termination, we reserve the right, in our sole discretion, to terminate your access to all or any part of the Website and/or Services at any time, with or without notice, effective immediately. Any such termination may result in the immediate forfeiture and destruction of data associated with your account. Except as otherwise set forth herein or expressly agreed between the parties, any and all fees paid to us are non-refundable and any and all fees still owed to us at the time of such termination shall be immediately due and payable. Upon termination, any and all rights granted to you by these Terms will immediately be terminated, and you must also promptly discontinue all use of the Website and/or Services. All provisions of these Terms which by their nature should reasonably be expected to survive termination shall survive termination.</p>
                        <p className="mt-4">5.3. If Turf Dev Pvt. Ltd. provides you with a translation of the English language version of these Terms, the English language version of these Terms will prevail over any other language version in case of any conflict.</p>
                        <p className="mt-4">5.4. You irrevocably agree to accept all notices, of whatever nature, legal or otherwise, by electronic mail or other digital delivery system at the discretion of Turf Dev Pvt. Ltd. for any matter or any proceeding for which notice is required.</p>
                        <p className="mt-4">5.5. The Services may include hyperlinks to other websites, content or resources. Turf Dev Pvt. Ltd. may have no control over any web sites or resources which are provided by companies or persons other than Turf Dev Pvt. Ltd.. The User agrees to be bound exclusively by the terms and conditions of any such third party web site.</p>
                        <p className="mt-4">5.6. You acknowledge and agree that Turf Dev Pvt. Ltd. is not responsible for the availability of any such external sites or resources as described in this agreement, and does not endorse any advertising, products or other materials on or available from such websites or resources.</p>
                        <p className="mt-4">5.7. You agree that if Turf Dev Pvt. Ltd. does not exercise or enforce any legal right or remedy which is contained in the Terms (or which Turf Dev Pvt. Ltd. has the benefit of under any applicable law), this will not be taken to be a formal waiver of Turf Dev Pvt. Ltd. rights and that those rights or remedies will still be available to Turf Dev Pvt. Ltd.</p>
                        <p className="mt-4">5.8. Turf Dev Pvt. Ltd. employs industry standards technology and practices to store and protect your Cloud Provider and Services login information. In no case shall Turf Dev Pvt. Ltd. be held liable for any damages incurred as a result of such network or software related breach. In case of a breach Turf Dev Pvt. Ltd. will notify you via email or other means, as Turf Dev Pvt. Ltd. deems sufficient, to take necessary actions from your end.</p>
                        <p className="mt-4">5.9. These, including all documents expressly incorporated by reference herein, constitutes the entire agreement between the parties and supersedes all prior and contemporaneous agreements, proposals or representations, written or oral, concerning its subject matter. Use of section headers in these Terms are for convenience only and will not have any impact on the interpretation of any provision. Any use of the singular shall be reasonably construed as if it also includes the plural, and vice versa. If not already specified, any use of “including” or “such as” shall be construed to mean “including but not limited to.” If any part of these Terms is held to be invalid or unenforceable, the unenforceable part will be given effect to the greatest extent possible, and the remaining parts will remain in full force and effect. The failure to require performance of any provision will not affect our right to require performance at any other time after that, nor will a waiver by us of any breach or default of these Terms, or any provision of these Terms, be a waiver of any subsequent breach or default or a waiver of the provision itself.</p>
                        <p className="mt-4">5.10. Turf Dev Pvt. Ltd. may automatically back up and shut down applications and servers at various times, including without limitation, when you log out of the Service, if you do not respond to a prompt after a certain period of time, or if you explicitly request, or if Turf Dev Pvt. Ltd. detects that your application or server is malfunctioning. The data and software backed up during this process (a “Backup”) can be restored, but such restoration is not guaranteed to reproduce the exact state of the application or server at the time the Backup was created, particularly if you have used the “terminal” capability or otherwise directly accessed the Server. Turf Dev Pvt. Ltd. does not promise to retain any preservations or Backups or guarantee the completeness of any such back. You are solely responsible for the integrity, preservation and backup of your Services Content, regardless of whether your use of Services includes a backup feature or functionality, and to the fullest extent permitted by law, Turf Dev Pvt. Ltd. shall have no liability for any data loss, unavailability, or other consequences related to the foregoing.</p>
                        <p className="mt-4">5.11. Turf Dev Pvt. Ltd. reserves the right to modify or discontinue the features, functionality and other attributes of the Services at any time in its sole discretion. You acknowledge and agree that Turf Dev Pvt. Ltd. will not be liable to you in connection with its modification or discontinuation of the Services.</p>
                        <p className="mt-4">5.12 These Terms of Use and any dispute related thereto is governed by the laws of the State of New York without regard to conflict of law principles. You and Turf Dev Pvt. Ltd submit to the personal and exclusive jurisdiction of the state courts and federal courts located within New York County, New York for resolution of any lawsuit or court proceeding permitted under these Terms of Use.</p>
                        <p className="mt-4">5.13 Assignment. You may not assign, transfer or delegate these Terms of Use or any of your rights and obligations under these Terms of Use, in whole or in part, by operation of law or otherwise, without our prior written consent. We may assign, transfer or delegate these Terms of Use or our rights and obligations under these Terms of Use without notice or consent. Subject to this section, these Terms of Use will be binding upon and inure to the benefit of the parties and their respective permitted successors and assigns, and any assignment or transfer in violation of this section will be void.</p>

                        <h3 className="text-base font-bold mt-10 my-6">6. BILLING AND PAYMENTS</h3>
                        <p>6.1 We charge in arrears, meaning that you will receive an invoice during the first week of every month for the Services that You used in the previous month.</p>
                        <p className="mt-4">6.2 Poorva Cloud is a ‘pay as you go’ service, meaning you will only pay for the resources that you have actually used and for the time that you have actually used them (minimum time fraction for billing related purposes is one hour increments).</p>
                        <p className="mt-4">6.3 Poorva Cloud is liable to refund your unused prepaid account funds, if a refund request is presented within three months of funds addition. However, the refund will not include the amount already deducted for services consumed.</p>
                        <p className="mt-4">6.4 We use third-party payment processors (the “Payment Processors”) to bill you through the payment account(s) linked to your Account (your “Billing Information”). The processing of payments may be subject to the terms, conditions and policies of the Payment Processors in addition to this Terms. We are not responsible for acts or omissions of the Payment Processors. You agree to pay us, through the Payment Processors or as otherwise agreed to by Poorva Cloud, all sums for Services you select or use at applicable prices in accordance with our pricing and billing policies and you hereby authorize us and applicable Payment Processors to charge all such sums (including all applicable taxes) to the payment method(s) specified in or linked to your Account (your “Payment Method”).</p>
                        <p className="mt-4">6.5 You must provide current, complete and accurate information for your Account and Billing Information, and must promptly update all such information in the event of changes (such as a change in billing address, credit card number, or credit card expiration date). You must promptly notify us or our Payment Processors if your payment method is canceled (e.g., for loss or theft) or otherwise inoperable. Changes to such information can be made in your account settings.</p>
                        <p className="mt-4">6.6 By entering into these Terms and using the Services, you agree to be billed on a recurring basis and to be automatically charged by us or our Payment Processors using your Payment Methods upon invoicing. If your Payment Method or payment of fees is subject to other terms and conditions, as set forth in order forms, invoices or otherwise, then those other terms and conditions apply in addition to these Terms. You may also be billed an amount up to your current balance at any time to verify the accuracy of your account information. We reserve the right to deactivate, terminate, prevent access to, disable services for, and/or delete any Accounts or access to the Website and Services at any time at our sole discretion, including for nonpayment, late payment, or failure to charge your Payment Methods upon invoicing.</p>
                        <p className="mt-4">6.7 You are responsible for any duties, customs fees, taxes, and related penalties, fines, audits, interest and back-payments relating to your purchase of the Services, including but not limited to national, state or local sales taxes, use taxes, value-added taxes (VAT) and goods and services taxes (GST) (collectively, “Taxes”). Unless otherwise stated, our pricing policies do not include and are not discounted or enhanced for any such Taxes. If we become obligated to collect or pay Taxes in connection with your purchase of the Services, those Taxes will be invoiced to you as part of a billing process or collected at the time of purchase. In certain states, countries and territories, we may determine if your purchase of Services is subject to certain Taxes, and if so, may collect such Taxes and remit them to the appropriate taxing authority. If you believe that a given Tax does not apply or that some amount must be withheld from payments to us, you must promptly provide us with a tax certificate, withholding receipt, tax identifier (e.g., VAT ID) or other adequate proof, provided such information is valid and sufficiently authorized by all appropriate taxing authorities. You must also provide us with any tax identification information that is necessary for us to comply with our tax obligations, as we determine from time to time. You will be solely responsible for any misrepresentations made or non-compliance caused by you regarding Taxes, whether with respect to us or other parties, including any penalties, fines, audits, interest, back-payments or further taxes associated with such misrepresentations or non-compliance.</p>
                        <p className="mt-4">6.7 We may offer Service credits from time to time, and any provision of such credits is governed by the disclosures, terms and policies concerning the credits. You acknowledge and agree that all credits, whether previously offered or otherwise, are revocable or subject to early expiration at any time and for any reason or no reason by us, including for abuse, misrepresentation of Account information, unauthorized transfer, or illegal conduct by you.</p>

                        <h3 className="text-base font-bold mt-10 my-6">STATEMENT FOR MODERN DAY SLAVERY</h3>
                        <p>We confirm that neither Poorva Cloud nor any entity that it owns or controls is involved in Modern Slavery practices including servitude and forced or compulsory labor. We further confirm that Modern Slavery is not occurring in the business operations and activities of Poorva Cloud and any entity that it owns or controls.</p>
                        <p className="mt-4">We do not enter into business with any entity which, to the best of our knowledge, knowingly supports or is found to be involved in Modern Slavery practices.</p>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )

}

export default Privacy;