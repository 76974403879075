import React, {useEffect} from "react";
// import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';

import DashboardHeader from "../components/Dashboard-Header";
import DashboardOptions0 from "../components/Dashboard-Options-0"
import Copyright from "../components/Copyright";

function ListTickets(userData) {
    // const navigate = useNavigate()
    const user = userData.user;
    const compartment = "dashboard";

    useEffect(() => {
        document.title = "Poorva Cloud - Support tickets";
    }, []);

    const selectedItem0 = 'support';
    const selectedItem2 = 'mytickets';

    const ticketsData = [
        {department: "Technical", ticketNumber: "#TD97103572", subject: "Need help setting up WordPress", status: "answered", statusColor: ""},
        {department: "Sales and Billing", ticketNumber: "#TD90120396", subject: "Raise an invoice for my TD-XLARGE server", status: "open", statusColor: "text-green-700"},
        {department: "Technical", ticketNumber: "#TD06163902", subject: "Website throwing a 500 internal server error", status: "closed", statusColor: ""},
        {department: "Technical", ticketNumber: "#TD02537529", subject: "Having problem creating a new domain", status: "customerReply", statusColor: "text-red-500"},
        {department: "Sales and Billing", ticketNumber: "#TD72017423", subject: "Server is terminated", status: "inProgress", statusColor: "text-orange-500"}
    ];

    return (
        <>
            <DashboardHeader userData={user} selectedItem0={selectedItem0} selectedItem2={selectedItem2} compartment={compartment}/>
            <div className="flex lg:flex-row flex-col">
                
                <div className="hidden lg:block lg:w-[27%]">
                    <DashboardOptions0 selectedItem0={selectedItem0} selectedItem2={selectedItem2}/>
                </div>

                <div className="h-screen overflow-y-auto lg:w-[73%] bg-white">
                    <div className="bg-white text-black flex flex-col gap-2 m-5 md:shadow-custom3 rounded pb-10 pt-20 sm:pl-10 sm:pr-10 lg:pl-5 lg:pr-20 items-start">
                        
                        <div className="w-full">
                            <h1 className="uppercase text-xl font-bold text-left">Recent Tickets</h1>
                            
                            <div className="w-full mt-4 px-5">
                                <div className="text-left flex text-white p-2 bg-black">
                                    <div className="hidden md:flex w-[25%]">
                                        <h3 className="font-bold uppercase">Department</h3>
                                    </div>
                                    <div className="w-[50%]">
                                        <h3 className="font-bold uppercase">Subject</h3>
                                    </div>
                                    <div className="hidden md:flex w-[25%]">
                                        <h3 className="font-bold uppercase">Status</h3>
                                    </div>
                                </div>
                                
                                {ticketsData.map((ticket, index) => (
                                    <Link key={index} to="">
                                        <div className="text-left flex border-b border-gray-400 border-opacity-50 p-2 hover:bg-gray-100">
                                            <div className="hidden md:flex w-[25%]">
                                                <h3 className="font-bold">{ticket.department}</h3>
                                            </div>
                                            <div className="flex flex-col justify-start w-[50%]">
                                                <h3 className="font-bold">{ticket.ticketNumber}</h3>
                                                <h3 className="text-sm">{ticket.subject}</h3>
                                            </div>
                                            <div className="hidden md:flex w-[25%]">
                                                {ticket.status === "answered" && <h3 className="font-bold capitalize text-sm">{ticket.status}</h3>}
                                                {ticket.status === "open" && <h3 className="font-bold capitalize text-sm text-green-600">{ticket.status}</h3>}
                                                {ticket.status === "inProgress" && <h3 className="font-bold text-sm text-orange-500">In Progress</h3>}
                                                {ticket.status === "customerReply" && <h3 className="font-bold text-sm text-red-500">Customer-Reply</h3>}
                                                {ticket.status === "closed" && <h3 className="font-bold capitalize text-sm">{ticket.status}</h3>}
                                            </div>
                                        </div>
                                    </Link>
                                ))}

                            </div>

                        </div>

                    </div>
                </div>

            </div>

            <Copyright />
        </>
    )

}

export default ListTickets;
