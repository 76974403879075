import React, {useEffect} from "react";
// import { useNavigate } from "react-router-dom";
// import { Link } from 'react-router-dom';
import DashboardHeader from "../components/Dashboard-Header";
import DashboardOptions0 from "../components/Dashboard-Options-0"
import Copyright from "../components/Copyright";

function OpenTicket(userData) {
    // const navigate = useNavigate()
    const user = userData.user;
    const compartment = "dashboard";

    useEffect(() => {
        document.title = "Raise a ticket to get help from professional cloud engineers";
    }, []);

    const selectedItem0 = 'support';
    const selectedItem2 = 'openticket';
    
    return (
        <>
            <DashboardHeader userData={user} selectedItem0={selectedItem0} selectedItem2={selectedItem2} compartment={compartment}/>
            <div className="flex lg:flex-row flex-col">
                
                <div className="hidden lg:block lg:w-[27%]">
                    <DashboardOptions0 selectedItem0={selectedItem0} selectedItem2={selectedItem2} />
                </div>
                
                <div className="h-screen overflow-y-auto lg:w-[73%] bg-white">
                    <div className="bg-white text-black flex flex-col gap-2 m-5 md:shadow-custom3 rounded sm:pl-10 sm:pr-10 py-20 lg:pl-5 lg:pr-20 items-start">

                        <div className="w-full">
                            <h1 className="uppercase text-xl font-bold text-left">Open Ticket</h1>
                            
                            <div className="mt-5 flex flex-col gap-4 px-5">
                                <div className="flex md:flex-row flex-col gap-2">
                                    <div className="flex-1 text-left flex flex-col">
                                        <label htmlFor="name" className="uppercase font-bold">Name</label>
                                        <input className="opacity-70 w-[100%] bg-black text-white cursor-not-allowed border border-black rounded-md py-2 px-3" defaultValue="Faheem Ahmed" type="text" name="name" id="name" disabled />
                                    </div>
                                    <div className="flex-1 text-left flex flex-col">
                                        <label htmlFor="email" className="font-bold uppercase">Email address</label>
                                        <input className="opacity-70 w-[100%] bg-black text-white cursor-not-allowed border border-black rounded-md py-2 px-3" defaultValue="faheem@turfdev.in" type="text" name="email" id="email" disabled />
                                    </div>
                                </div>

                                <div className="flex md:flex-row flex-col gap-2">
                                    <div className="flex-1 text-left">
                                        <h1 className="font-bold uppercase">Department</h1>
                                        <select className="w-full border-2 border-custom-green bg-black text-white rounded-md px-1 py-[5.5px]" defaultValue={'DEFAULT'}>
                                            <option value="DEFAULT">Technical</option>
                                            <option value="sales">Sales and Billing</option>
                                        </select>
                                    </div>
                                    <div className="flex-1 text-left">
                                        <h1 className="font-bold uppercase">Related Service</h1>
                                        <select className="w-full border-2 border-custom-green bg-black text-white rounded-md px-1 py-[5.5px]" defaultValue={'DEFAULT'}>
                                            <option value="DEFAULT">Select Service</option>
                                            <option value="server_tdshop_in">server.tdshop.in</option>
                                            <option value="server_turf_com">server.turf.com</option>
                                            <option value="server_yurfdev_in">server.turfdev.in</option>
                                        </select>
                                    </div>
                                    <div className="flex-1 text-left">
                                        <h1 className="font-bold uppercase">Priority</h1>
                                        <select className="w-full border-2 border-custom-green bg-black text-white rounded-md px-1 py-[5.5px]" defaultValue={'DEFAULT'}>
                                            <option value="DEFAULT">Medium</option>
                                            <option value="">High</option>
                                            <option value="">Low</option>
                                            
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="mt-5 flex flex-col gap-2 px-5">
                                <div className="text-left flex flex-col">
                                    <label htmlFor="subject" className="uppercase font-bold">Subject</label>
                                    <input className="md:w-[80%] w-[100%] bg-black text-white border border-black rounded-md py-2 px-3" placeholder='Subject' type="text" name="subject" id="subject" />
                                </div>
                                <div className="text-left flex flex-col md:w-[80%]">
                                    <label htmlFor="message" className="uppercase font-bold">Message</label>
                                    <div className="flex flex-col sm:flex-row gap-2 border-t border-x border-black rounded-t">
                                        <div className="flex flex-wrap w-[70%] p-1 gap-1">
                                            <button className="flex-1 bg-transparent hover:bg-gray-200 px-2 py-1 border border-black" title="Bold"><b>B</b></button>
                                            <button className="flex-1 bg-transparent hover:bg-gray-200 px-2 py-1 border border-black" title="Italic"><i>I</i></button>
                                            <button className="flex-1 bg-transparent hover:bg-gray-200 px-2 py-1 border border-black" title="Heading"><i className="fas fa-heading"></i></button>
                                            
                                            <button className="mx-2 flex-1 bg-transparent hover:bg-gray-200 px-2 py-1 border border-black" title="Link"><i className="fas fa-link"></i></button>

                                            <button className="flex-1 bg-transparent hover:bg-gray-200 px-2 py-1 border border-black" title="Quote"><i className="fas fa-quote-left"></i></button>
                                            <button className="flex-1 bg-transparent hover:bg-gray-200 px-2 py-1 border border-black" title="Code"><i className="fas fa-code"></i></button>
                                            <button className="flex-1 bg-transparent hover:bg-gray-200 px-2 py-1 border border-black" title="Ordered List"><i className="fas fa-list-ol"></i></button>
                                            <button className="flex-1 bg-transparent hover:bg-gray-200 px-2 py-1 border border-black" title="Unordered List"><i className="fas fa-list-ul"></i></button>
                                        </div>
                                        <div className="p-1 flex items-end">
                                            <button type="submit" className="button1 flex gap-1"><i className="fa-solid fa-magnifying-glass"></i> Preview</button>
                                        </div>
                                    </div>
                                    <textarea type="text" id="message" name="message" className="border border-black rounded-b focus:ring-black focus:ring-1 focus:outline-none p-1 justify-center items-center bg-transparent mb-2" rows={6} />
                                </div>
                            </div>
                            
                            <div className="mt-5 px-5 text-left flex sm:items-end flex-col sm:flex-row gap-2">
                                <div className="flex flex-col gap-2 flex-1">
                                    <label htmlFor="" className="uppercase font-bold">Attachment</label>
                                    <input type="file" id="attachment" name="attachment" className="border border-black rounded focus:ring-black focus:ring-1 focus:outline-none p-1 justify-center items-center bg-transparent" />
                                </div>
                                <div className="">
                                    <button type="button" className="button1 flex gap-1"
                                        onClick={() => {
                                            const input = document.createElement('input');
                                            input.type = 'file';
                                            input.name = 'attachment';
                                            input.className = 'border border-black rounded focus:ring-black focus:ring-1 focus:outline-none p-1 justify-center items-center bg-transparent';
                                            document.getElementById('attachment').parentNode.appendChild(input);
                                        }}>
                                        <i className="fa-solid fa-plus"></i> Add More
                                    </button>
                                </div>
                            </div>

                            <div className="mt-10 flex sm:flex-row flex-col gap-2 px-5">
                                <button className="elastic-button1 w-auto sm:w-32" type="submit">Submit</button>
                                <button className="elastic-button2 w-auto sm:w-32" type="submit">Cancel</button>
                            </div>

                        </div>

                    </div>
                </div>

            </div>

            <Copyright />
        </>
    )

}

export default OpenTicket;
