export const serverSizes = [
    {name: "Micro", value: 't3.micro', details: {Memory: '1 GB', Core: '2 Cores'}, price: {Hourly: 0.0209, Monthly: 15.62}},
    // {name: "Small", value: 't4g.small', details: {Memory: '2 GB', Core: '2 Cores'}, price: {Hourly: 0.0434, Monthly: 31.25}},
    // {name: "Medium", value: 't4g.medium', details: {Memory: '4 GB', Core: '2 Cores'}, price: {Hourly: 0.0868, Monthly: 62.50}},
    // {name: "Large", value: 't4g.large', details: {Memory: '8 GB', Core: '2 Cores'}, price: {Hourly: 0.1736, Monthly: 124.99}},
    // {name: "CO XL", value: 'c7g.xlarge', details: {Memory: '8 GB', Core: '4 Cores'}, price: {Hourly: 0.3746, Monthly: 269.70}},
    {name: "Small", value: 't3.small', details: {Memory: '2 GB', Core: '2 Cores'}, price: {Hourly: 0.0434, Monthly: 31.25}},
    {name: "Medium", value: 't3.medium', details: {Memory: '4 GB', Core: '2 Cores'}, price: {Hourly: 0.0868, Monthly: 62.50}},
    {name: "Large", value: 't3.large', details: {Memory: '8 GB', Core: '2 Cores'}, price: {Hourly: 0.1736, Monthly: 124.99}},
    {name: "CO XL", value: 'c3.xlarge', details: {Memory: '8 GB', Core: '4 Cores'}, price: {Hourly: 0.3746, Monthly: 269.70}},
    {name: "XL", value: 't4g.xlarge', details: {Memory: '16 GB', Core: '4 Cores'}, price: {Hourly: 0.3472, Monthly: 249.98}},
    {name: "CO 2XL", value: 'c6g.2xlarge', details: {Memory: '16 GB', Core: '8 Cores'}, price: {Hourly: 0.7027, Monthly: 505.92}},
    {name: "2XL", value: 't4g.2xlarge', details: {Memory: '32 GB', Core: '8 Cores'}, price: {Hourly: 0.6944, Monthly: 499.97}},
    {name: "CO 4XL", value: 'c7a.4xlarge', details: {Memory: '32 GB', Core: '16 Cores'}, price: {Hourly: 2.1212, Monthly: 1527.28}},
    {name: "4XL", value: 'm6g.4xlarge', details: {Memory: '64 GB', Core: '16 Cores'}, price: {Hourly: 1.5913, Monthly: 1145.76}},
    // {name: "CO 8XL", value: 'c7g.8xlarge', details: {Memory: '64 GB', Core: '32 Cores'}, price: {Hourly: 2.9967, Monthly: 2157.60}},
    {name: "CO 9XL", value: 'c5.9xlarge', details: {Memory: '72 GB', Core: '36 Cores'}, price: {Hourly: 3.9525, Monthly: 2845.80}},
    {name: "8XL", value: 'm6g.8xlarge', details: {Memory: '128 GB', Core: '32 Cores'}, price: {Hourly: 3.1827, Monthly: 2291.51}},
    {name: "CO 12XL", value: 'c7g.12xlarge', details: {Memory: '96 GB', Core: '48 Cores'}, price: {Hourly: 4.4950, Monthly: 3236.40}},
    {name: "12XL", value: 'm6g.12xlarge', details: {Memory: '192 GB', Core: '48 Cores'}, price: {Hourly: 4.7740, Monthly: 3437.28}},
    {name: "CO 18XL", value: 'c5.18xlarge', details: {Memory: '144 GB', Core: '72 Cores'}, price: {Hourly: 7.9050, Monthly: 5691.60}},
    {name: "16XL", value: 'm6g.metal', details: {Memory: '256 GB', Core: '64 Cores'}, price: {Hourly: 6.3653, Monthly: 4583.04}},
    {name: "CO 24XL", value: 'c6a.24xlarge', details: {Memory: '192 GB', Core: '96 Cores'}, price: {Hourly: 9.4860, Monthly: 6829.92}},
    {name: "24XL", value: 'm5a.24xlarge', details: {Memory: '384 GB', Core: '96 Cores'}, price: {Hourly: 10.6640, Monthly: 7678.08}},
];